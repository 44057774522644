import React, { Component, useState } from "react";
import ListingPage, { TABLE_ACTIONS } from "../../../../containers/ListingPage";
import StoreSelector from "../../../../containers/StoreSelector";
import { getMessage } from "../../../../lib/translator";
import OrderFilters from "./Filters";
import { tabularView } from "./Views";
import { Link } from "react-router-dom";
import API from "../../../../lib/api";
import { isExtensionEnabled } from "../../../../lib/auth";
import emptyIcon from "./no-orders.svg";
import rcmIcon from "./rcm.svg";
import seoIcon from "./seo.svg";
import newCustomerIcon from "./new-customer.svg";
import Tabs from "../../../../components/Tabs";
import { getSortedOrderStatus } from "../../../../lib/commonlyused";
import { faro } from "@grafana/faro-web-sdk";
import { Popup } from "../../../../components/Popup";
import { DropDown, DropDownItem } from "../../../../components/DropDown";
import OrderUpload from "../OrderUpload";

import "./style.css";
import checkHttpResponseCode from "../../../../utils/isApiCallSuccessfull";

function CreateNewOrder() {
  const [showPopup, setShowPopup] = useState(false);
  const showModal = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="text-center">
      <Popup
        heading={getMessage("order.create.bulk.text")}
        show={showPopup}
        close={showModal}
        className="bulk-upload"
      >
        <OrderUpload />
      </Popup>
      <DropDown
        className="bulk-upload-dropdown"
        icon={
          <button className="button primary create-order-dropdown-button">
            + <span className="text">{getMessage("order.add.text")}</span>
          </button>
        }
      >
        <DropDownItem>
          <Link to="/orders/orders/add">{getMessage("order.add.text")}</Link>
        </DropDownItem>
        <DropDownItem onClick={showModal}>
          {getMessage("order.create.bulk.text")}
        </DropDownItem>
      </DropDown>
    </div>
  );
}

const emptyState = (props) => {
  return {
    icon: emptyIcon,
    message: getMessage("order.emptyList.message"),
    actions: ({ onAction, apiParams }) => (
      <React.Fragment>
        <CreateNewOrder />
        {isExtensionEnabled("MultiStoreSupport") && (
          <StoreSelector
            value={apiParams.storeId}
            stores={props.stores}
            onChange={(storeId) => {
              onAction(TABLE_ACTIONS.SET_API_PARAMS, { storeId });
            }}
          />
        )}
      </React.Fragment>
    ),
  };
};

const helpItems = {
  title: getMessage("order.helpItems.title"),
  instructions: [
    {
      icon: seoIcon,
      title: getMessage("order.helpItems.seo.title"),
      description: getMessage("order.helpItems.seo.description"),
    },
    {
      icon: rcmIcon,
      title: getMessage("order.helpItems.rcm.title"),
      description: getMessage("order.helpItems.rcm.description"),
    },
    {
      icon: newCustomerIcon,
      title: getMessage("order.helpItems.nca.title"),
      description: getMessage("order.helpItems.nca.description"),
    },
  ],
};

const { Provider, Consumer } = React.createContext("commConfig");

export const getAllOrderTabs = () => {
  const orderTabs = [
    "ALLORDERS",
    "PENDING",
    "INPROGRESS",
    "COMPLETED",
    "CANCELLED",
  ];
  if (isExtensionEnabled("OrderReturns")) {
    orderTabs.push("RETURNED");
  }
  return orderTabs;
};

const OrdersContainer = class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderTab: window.localStorage.getItem(`orderPage-tabIndex`) || 0,
      emailInput: null,
      phoneInput: null,
      customerIdInput: null,
      queryParams: null,
    };
    this.ordersTabStatus = getAllOrderTabs();
  }

  componentDidMount() {
    this.communicationApi = new API({
      url: `/account-service/config/communication`,
    });
    this.communicationApi
      .get()
      .then((response) => {
        this.setState({ communicationConfig: response.data.config });
      })
      .catch((error) => {
        throw error;
      });

    if (isExtensionEnabled("InStoreProcessing")) {
      this.api = new API({
        url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
      });
      this.api
        .get()
        .then((res) => {
          if (checkHttpResponseCode(res.code) && res.status === "SUCCESS") {
            this.setState({
              packedOrderEdit:
                res &&
                res.data &&
                res.data.inStoreProcessing &&
                res.data.inStoreProcessing.packedOrderEditAllowed,
            });
          }
        })
        .catch((err) => {
          if (err.code === 401 || err.code === 403) {
            throw err;
          }
          if (faro?.api?.pushError) {
            faro.api.pushError(err);
          }
        });
    }
  }

  componentWillUnmount() {
    window.localStorage.removeItem(`orderPage-tabIndex`);
    this.communicationApi && this.communicationApi.cancel();
  }

  changeTab = (tab) => {
    window.localStorage.setItem(`orderPage-tabIndex`, tab);
    this.props.history.push({ search: "" });
    this.setState({
      emailInput: null,
      phoneInput: null,
      customerIdInput: null,
    });
    this.setState({
      orderTab: tab,
    });
  };

  fetchTableDataOnStatus = (tab) => {
    let queryParams = null;
    if (tab === "INPROGRESS") {
      const notAllowedArray = ["PENDING", "COMPLETED", "CANCELLED", "RETURNED"];
      const allAllowedStatus = getSortedOrderStatus();
      const modifiedStatus = allAllowedStatus.filter(
        (item) => !notAllowedArray.includes(item)
      );
      queryParams = {
        status: modifiedStatus,
      };
    }
    if (tab === "PENDING") queryParams = { status: "PENDING" };
    if (tab === "COMPLETED") queryParams = { status: "COMPLETED" };
    if (tab === "CANCELLED") queryParams = { status: "CANCELLED" };
    if (tab === "RETURNED") queryParams = { status: "RETURNED" };

    return queryParams;
  };

  getCustomerId(formData) {
    const getCustomerId = new API({
      url: "/customer-service/customer",
    });
    const params = {
      email: formData?.email,
      phone: formData?.phone,
    };
    return getCustomerId.get(params, { "X-API-VERSION": 2 });
  }
  render() {
    const { props } = this;
    // If url is present in the props, then we need to show customer support related interfaces

    const tab = this.ordersTabStatus[this.state.orderTab];
    const queryParams = this.fetchTableDataOnStatus(tab);

    return (
      <Provider value={this.state && this.state.communicationConfig}>
        <ListingPage
          helmetTitle="Zopping - Orders"
          key={tab}
          menu={props.menu}
          className={`orders-page ${this.props.className || "ops"}`}
          title={getMessage("menu.item.orders")}
          api={{
            url: "/order-service/order",
            params: {
              ...props.params,
              ...queryParams,
              "includes[1]": "courierDetails",
            },
            updateApiParams: props.updateApiParams
              ? props.updateApiParams
              : null,
            transform: (response) => {
              if (
                (this.state.emailInput || this.state.phoneInput) &&
                !this.state.customerIdInput
              ) {
                return [];
              }
              return response.data.order;
            },
          }}
          primaryKey="referenceNumber"
          emptyState={props.emptyState ? props.emptyState() : emptyState(props)}
          helpItems={helpItems}
          filters={{
            component: OrderFilters,
            transformFilter: (formData) => {
              let result = Object.assign({}, formData);
              if (result.customer) {
                result.customerId = result.customer.id;
                delete result.customer;
              }
              if (result.slot) {
                let slotDetails = JSON.parse(result.slot);
                if (slotDetails.startTime) {
                  result.slotStartTime = slotDetails.startTime;
                }
                result.slotEndTime = slotDetails.endTime;
                result.type = slotDetails.type;
                delete result.slot;
              }
              return result;
            },
            transformSubmit: async (formData) => {
              let searchParams = "";
              Object.keys(formData)
                .filter(
                  (key) =>
                    key !== "filters" && (formData[key] === 0 || formData[key])
                )
                .forEach((key, index) => {
                  if (key !== "email" && key !== "phone") {
                    if (index === 0) {
                      searchParams += `?${key}=${formData[key]}`;
                    } else {
                      searchParams += `&${key}=${formData[key]}`;
                    }
                  }
                });
              let result = Object.assign({}, formData);
              if (result.customer) {
                result.customerId = result.customer.id;
                delete result.customer;
              }
              if (result.slot) {
                let slotDetails = JSON.parse(result.slot);
                if (slotDetails.startTime) {
                  result.slotStartTime = slotDetails.startTime;
                }
                result.slotEndTime = slotDetails.endTime;
                result.type = slotDetails.type;
                delete result.slot;
              }
              if (result?.email || result?.phone) {
                await this.getCustomerId(result)
                  .then((res) => {
                    result.customerId = res.data?.customer?.[0]?.id;
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    if (formData?.email || formData?.phone) {
                      this.setState((prevState) => ({
                        ...prevState,
                        emailInput: formData?.email,
                        phoneInput: formData?.phone,
                        customerIdInput: result?.customerId,
                      }));
                    }
                  });
              }
              this.props.history.push({ search: searchParams });
              delete result?.email;
              delete result?.phone;
              return result;
            },
            forceShow: true,
          }}
          onClear={() => {
            this.setState((prevState) => ({
              ...prevState,
              emailInput: null,
              phoneInput: null,
              customerIdInput: null,
            }));
            this.props.history.push({ search: "filters=all" });
          }}
          headerActions={({ apiParams, onAction }) =>
            this.props.url.includes("orders") ? (
              <React.Fragment>
                <CreateNewOrder />
              </React.Fragment>
            ) : null
          }
          tableProperties={tabularView(
            this.props.url,
            this.state && this.state.packedOrderEdit
          )}
          tableDynamic
          storeDependent={this.props.url === "/orders"}
          additionalViews={[
            () => (
              <div className="tab-wrp">
                <Tabs
                  items={this.ordersTabStatus.map((tab) =>
                    getMessage(`order.status.${tab.toLowerCase()}`)
                  )}
                  default={Number(this.state.orderTab)}
                  onClick={(index) => {
                    this.changeTab(index);
                  }}
                />
              </div>
            ),
          ]}
          form={{ overwriteWithApiParams: false }}
        />
      </Provider>
    );
  }
};

export default OrdersContainer;

export { Provider, Consumer };
