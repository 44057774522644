// eslint-disable-next-line
import React from "react";
import "./style.css";

type HelpCards = {
  onAction?: <T>() => T;
  icon: string;
  title: string;
  children: any;
};

export default function HelpCard(props: HelpCards) {
  let onAction = props.onAction;
  return (
    <div
      className={`helpCard ${onAction ? "actions" : ""} `}
      onClick={onAction}
    >
      <div className="image-with-title">
        <img src={props.icon} alt="" />
        {onAction ? (
          <div className="title action">{props.title}</div>
        ) : (
          <div className="title">{props.title}</div>
        )}
      </div>
      <p className="card-text">{props.children}</p>
    </div>
  );
}
