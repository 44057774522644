import React from "react";

import Upload from "../../../../components/FileUpload";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import csvIcon from "./csv-icon.svg";
import uploadIcon from "./upload-products.svg";
import "./style.css";
import { getCurrentStoreId } from "../../../../lib/auth";

function downloadCsv(data) {
  let csv = "";
  csv += data.join(",");
  csv += "\n";
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  hiddenElement.download = "order.csv";
  hiddenElement.click();
}

export default function OrderUpload() {
  const getSampleCsv = (e) => {
    e.preventDefault();
    const api = new API({ url: "/order-service/order-upload" });
    api
      .get()
      .then(
        (response) => {
          downloadCsv(response.data.orderUpload);
        },
        (error) => {
          this.setState({ uploadError: error.message });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const storeId = getCurrentStoreId();
  return (
    <div className="order-upload">
      <div className="bordered-box">
        <div className="text-center">
          <img src={uploadIcon} width="140" alt="" />
        </div>

        <Upload
          name="bulkOrderUpload"
          accept=".csv, text/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
          placeholder={getMessage("orderUpload.uploadFileText")}
          uploadButtonText="Upload"
          cancelText="Cancel"
          uploadUrl={"/order-service/order-file"}
          validationStrings={{
            invalidFileType: getMessage("input.invalidFileType"),
          }}
          strings={{
            defaultMessage: getMessage("fileUpload.importCsv.heading"),
            progressMessage: getMessage("fileUpload.importedCsv.heading"),
            completionMessage: getMessage("orderUpload.uploadedCsv.heading"),
          }}
          icon={csvIcon}
          uploadKey={"file"}
          storeId={storeId}
        />
      </div>
      <div className="text-center">
        <button className="download-link" onClick={getSampleCsv}>
          {getMessage("orderUpload.csvDownloadText")}
        </button>
      </div>
    </div>
  );
}
