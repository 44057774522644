import React, { Component } from "react";
import ReactDOM from "react-dom";
import Loader from "../../components/Loader";
import { DropDown, DropDownItem } from "../../components/DropDown";
import API from "../../lib/api";
import { isExtensionEnabled, getStores } from "../../lib/auth";
import { get, set } from "../../lib/storage";
import StoreSelectorForm from "./Form";

import icon from "./drop-down-arrow.svg";
import "./style.css";

function getDefaultStore(stores) {
  let result = {};
  let orgStores = getStores();
  if (stores && stores.length && get("store")) {
    let store = get("store");
    result = {
      storeId: Number(store),
    };
    return result;
  }
  if (stores && stores.length) {
    result = {
      storeId: stores[0].id,
    };
  }
  if (orgStores && orgStores.length) {
    result = {
      storeId: orgStores[0].id,
    };
  }
  return result;
}

function makestoreDependentComponent(OriginalComponent) {
  return class storeDependentComponent extends Component {
    constructor(props) {
      super(props);
      this.hasMultiStoreSupport = isExtensionEnabled("MultiStoreSupport");
      if (this.hasMultiStoreSupport) {
        this.state = {
          loading: false,
          error: false,
          stores: null,
        };
      }
    }
    componentWillMount() {
      if (this.hasMultiStoreSupport) {
        if (get("stores")) {
          this.setState({
            stores: JSON.parse(get("stores")),
          });
        } else {
          if (get("user")) {
            let user = JSON.parse(get("user"));
            if (user.stores && user.stores.length > 0) {
              this.setState({
                stores: user.stores,
              });
            } else {
              let api = new API({ url: "/account-service/store" });
              api.get({ paginate: "false" }).then((response) => {
                this.setState({ stores: response.data.store.reverse() });
              });
            }
          } else {
            let api = new API({ url: "/account-service/store" });
            api.get({ paginate: "false" }).then((response) => {
              this.setState({ stores: response.data.store.reverse() });
            });
          }
        }
      }
    }
    render() {
      if (!this.hasMultiStoreSupport) {
        return <OriginalComponent {...this.props} />;
      }
      if (this.state.loading) {
        return <Loader />;
      }
      if (this.state.stores) {
        return <OriginalComponent {...this.props} stores={this.state.stores} />;
      }
      return null;
    }
  };
}

function updateStore(id, onChange) {
  set("store", id);
  onChange(id);
}

const addMenuMargin = (stores) => {
  if (!window.location.pathname.includes("home")) {
    const menuDiv = document.querySelector(".menu-items");
    if (stores.length > 1) {
      menuDiv.style.marginTop = "40px";
    }

    if (window.innerWidth <= 640 && stores.length > 1) {
      menuDiv.style.marginTop = "30px";
    }
  }
};

export default class StoreSelector extends Component {
  render() {
    if (!this.props.stores || !this.props.value) {
      return null;
    }
    let stores = this.props.stores || [];
    const logistics = window.location.pathname.includes("logistics");
    if (logistics) {
      stores = stores.filter((store) => store.hasDeliveryHub);
    }
    const operations = window.location.pathname.includes("orders");
    if (operations) {
      stores = stores.filter(
        (store) => store.hasPicking || store.hasClickCollect
      );
    }

    let selectedStore = stores.filter(
      ({ id }) => id === Number(this.props.value)
    )[0];
    if (!selectedStore) {
      selectedStore = stores[0];
      selectedStore && set("store", selectedStore.id);
    }
    if (!selectedStore) {
      selectedStore = {};
    }
    addMenuMargin(stores);

    return ReactDOM.createPortal(
      window.location.pathname.includes("home") ? null : stores &&
        stores.length < 5 ? (
        <>
          {stores.length > 1 ? (
            <div className="store-selector">
              <div className="selected-store" onClick={this.simulateClick}>
                {selectedStore.name}
              </div>
              <DropDown icon={<img src={icon} alt="v" />} drop>
                {stores.map(({ id, name }) => (
                  <DropDownItem
                    className={
                      parseInt(this.props.value, 10) === id ? "selected" : ""
                    }
                    key={id}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (parseInt(this.props.value, 10) === id) {
                        return;
                      }
                      updateStore(id, this.props.onChange);
                    }}
                  >
                    {name}
                  </DropDownItem>
                ))}
              </DropDown>
            </div>
          ) : null}
        </>
      ) : (
        <StoreSelectorForm
          selectedStore={selectedStore.name}
          stores={stores || []}
          onChange={this.props.onChange}
          updateStore={updateStore}
        />
      ),
      document.getElementById("store-selector")
    );
  }
}

export { getDefaultStore, makestoreDependentComponent };
