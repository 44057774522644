import React from "react";
import {
  BaseForm,
  Checkbox,
  Input,
  Select,
} from "../../../../../components/Form";
import Loader from "../../../../../components/Loader";
import AuthenticatedPage from "../../../../../containers/AuthenticatedPage";
import Table, { Header, Row, Cell } from "../../../../../components/Table";

import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import { isExtensionEnabled } from "../../../../../lib/auth";
import { getStepValue } from "../../../../../lib/commonlyused";

import "./style.css";

class FileForReturnForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.loading = false;
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    // this api is used for get the reasons for return items
    const returnReasonApi = new API({ url: `/account-service/config/order` });
    returnReasonApi
      .get()
      .then((response) => {
        this.setState({
          returnReasons: response.data.config.order.itemReturnReasons,
        });
      })
      .catch((error) => {
        throw error;
      });

    const referenceNumber = this.props.match.params.id;
    const api = new API({ url: `/order-service/order/${referenceNumber}` });
    api.get().then(
      (response) => {
        let transformedResponse = response.data.order;
        let items = transformedResponse.items || [];
        let status = transformedResponse.status;
        let tripId = transformedResponse.tripId;
        if (status === "DISPATCHED" && isExtensionEnabled("LogisticsSupport")) {
          const logisticsApi = new API({ url: "/logistics-service/trip" });
          logisticsApi
            .get({ orderNumber: referenceNumber })
            .then((response) => {
              if (response.data.trip[0]) {
                let tripId = response.data.trip[0].id;
                this.tripId = tripId;
                this.helper(
                  items,
                  status,
                  referenceNumber,
                  tripId,
                  transformedResponse
                );
              } else {
                this.helper(
                  items,
                  status,
                  referenceNumber,
                  tripId,
                  transformedResponse
                );
              }
            });
        } else {
          this.helper(
            items,
            status,
            referenceNumber,
            tripId,
            transformedResponse
          );
        }
      },
      (error) => {
        this.setState({
          formError: error.message,
          loading: false,
        });
        if (error.code === 401) {
          throw error;
        }
      }
    );
  }

  helper(
    items = [],
    status,
    referenceNumber,
    tripId = null,
    transformedResponse
  ) {
    items = getItemsToReturn(items, status, referenceNumber, tripId);
    items = calculateReturnableQuantity(items, status);
    items.forEach((item) => {
      item.quantity = item.returnableQuantity;
    });
    let moveableStatuses = getMoveToStatusOptions(status);
    this.setState({
      items: items,
      loading: false,
      moveableStatuses,
      referenceNumber: referenceNumber,
      orderStatus: status,
      order: transformedResponse,
      tripId: tripId,
      values: {
        items: items,
      },
    });
    return items;
  }

  onSubmit(data) {
    this.setState({
      submitting: true,
    });
    let formData = Object.assign({}, data);
    let orderStatus = this.state.orderStatus;
    let itemsToReturn =
      formData.items && formData.items.filter((item) => item.return && item);

    if (orderStatus === "DISPATCHED" && formData.status !== "COMPLETED") {
      itemsToReturn = this.helper(
        this.state.order.items,
        this.state.orderStatus,
        this.state.referenceNumber,
        this.state.tripId,
        this.state.order
      );
    }
    let returnItems = itemsToReturn.map((item) => {
      let returnItem = {
        id: item.orderDetails.orderItemId,
        quantity: item.quantity,
      };
      if (item.isPending) {
        if (
          this.state.orderStatus === "DISPATCHED" &&
          formData.status === "COMPLETED"
        ) {
          returnItem.qty = item.quantity;
          delete returnItem.quantity;
        } else {
          returnItem.isPending = true;
        }
      }
      if (item.returnReason) {
        returnItem.reason = item.returnReason;
      }
      return returnItem;
    });
    let api = new API({
      url: `/order-service/order/${this.state.referenceNumber}/returns`,
    });
    let params = {};
    params.returnItems = returnItems;
    if (formData.status) {
      params.status = formData.status;
    }
    if (this.tripId) {
      params.tripId = this.tripId;
    }
    if (orderStatus === "DISPATCHED" && formData.status === "COMPLETED") {
      api = new API({
        url: `/order-service/order/${this.state.referenceNumber}`,
      });
      params.returnItems = JSON.stringify(params.returnItems);
      api.put(params).then(
        (response) => {
          this.props.history.goBack();
        },
        (error) => {
          this.setState({
            returnError: error && error.message,
            submitting: false,
          });
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
        }
      );
    } else {
      api.post(params).then(
        (response) => {
          this.props.history.goBack();
        },
        (error) => {
          this.setState({
            returnError: error && error.message,
            submitting: false,
          });
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
        }
      );
    }
  }

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const {
      loading,
      values,
      moveableStatuses,
      returnError,
      orderStatus,
      returnReasons,
      order,
    } = this.state;
    const { items, status } = values;
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>
          {`${getMessage("return.heading.for")} `}
          {order && (
            <span className="text-muted">{order.clientId ? "#" : "Z"}</span>
          )}
          {order &&
            `${order.clientId ? order.clientId : this.state.referenceNumber}`}
        </h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="return-file-form">
            <Form>
              {returnError && <div className="form-error">{returnError}</div>}
              {moveableStatuses && items && items.length > 0 && (
                <Select
                  name="status"
                  options={moveableStatuses}
                  label={getMessage("return.move.order")}
                  placeholder={getMessage("return.move.placeholder")}
                  required
                  {...this.generateStateMappers({
                    stateKeys: ["status"],
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
              {items && items.length === 0 && (
                <div>{getMessage("return.no.items.to.return")}</div>
              )}
              {items && items.length > 0 && (
                <Table>
                  <Header>
                    <Cell>{getMessage("dispute.name")}</Cell>
                    <Cell>{getMessage("return.retunquantity")}</Cell>
                    <Cell>{getMessage("return.totalquantity")}</Cell>
                    {returnReasons && (
                      <Cell>{getMessage("return.reason.header")}</Cell>
                    )}
                  </Header>
                  {items &&
                    items.map((item, index) => {
                      let brand = item.brand && item.brand.name;
                      let name = item.fullName || item.name;
                      let orderItemId = item.orderDetails.orderItemId;
                      let editable =
                        orderStatus === "DISPATCHED"
                          ? !(status === "COMPLETED")
                          : !item.returnEditable;
                      let qty = Number(
                        this.getState(["items", index, "quantity"])
                      );
                      return (
                        <Row key={`return-item-${index}`}>
                          <Cell>
                            <Checkbox
                              inlineLabel={
                                <div>
                                  {brand && (
                                    <span className="text-muted">{brand} </span>
                                  )}
                                  {name}
                                </div>
                              }
                              key={orderItemId}
                              name={`return-${index}`}
                              value={this.getState(["items", index, "return"])}
                              onChange={(e) => {
                                this.updateState(["items", index, "return"], e);
                              }}
                              checked={this.getState([
                                "items",
                                index,
                                "return",
                              ])}
                              readOnly={editable}
                              secondaryLabel={() =>
                                brand && (
                                  <div className="text-muted">{brand}</div>
                                )
                              }
                            />
                          </Cell>
                          <Cell>
                            <Input
                              key={`quantity-${index}`}
                              name={`quantity-${index}`}
                              type="number"
                              min={0}
                              step={item.soldByWeight ? getStepValue(qty) : "1"}
                              readOnly={editable}
                              max={item.returnableQuantity}
                              {...this.generateStateMappers({
                                stateKeys: ["items", index, "quantity"],
                              })}
                              value={qty}
                            />
                          </Cell>
                          <Cell>{Number(item.returnableQuantity)}</Cell>
                          {returnReasons && (
                            <Cell>
                              <Select
                                name="returnReason"
                                placeholder={getMessage("return.reasons")}
                                {...this.generateStateMappers({
                                  stateKeys: ["items", index, "returnReason"],
                                  loseEmphasisOnFill: true,
                                })}
                                options={
                                  this.state.returnReasons
                                    ? this.state.returnReasons.map((reason) => {
                                        return {
                                          text: reason,
                                          value: reason,
                                        };
                                      })
                                    : []
                                }
                              />
                            </Cell>
                          )}
                        </Row>
                      );
                    })}
                </Table>
              )}
              {items && items.length > 0 && (
                <div className="form-action">
                  <SubmitButton disabled={this.state.submitting}>
                    {getMessage("submit.text")}
                  </SubmitButton>
                  <button
                    type="button"
                    className="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    {getMessage("cancel.text")}
                  </button>
                </div>
              )}
            </Form>
          </div>
        )}
      </AuthenticatedPage>
    );
  }
}

const getMoveToStatusOptions = (status) => {
  let moveableStatuses = [
    {
      text: getMessage("order.status.pending"),
      value: "PENDING",
    },
    {
      text: getMessage("order.status.packed"),
      value: "PACKED",
    },
    {
      text: getMessage("order.status.returned"),
      value: "RETURNED",
    },
    {
      text: getMessage("order.status.completed"),
      value: "COMPLETED",
    },
  ];

  if (status === "PACKED") {
    return [moveableStatuses[0], moveableStatuses[2]];
  } else if (status === "DISPATCHED") {
    if (isExtensionEnabled("InStoreProcessing")) {
      return moveableStatuses;
    }
    return [moveableStatuses[0], moveableStatuses[2], moveableStatuses[3]];
  } else if (status === "COMPLETED") {
    return false;
  }
};

const getItemsToReturn = (items, status, referenceNumber, tripId = null) => {
  let newItems = items.slice();
  if (status === "PACKED") {
    return newItems.filter((item) => item.orderDetails.status === "PICKED");
  } else if (status === "DISPATCHED") {
    if (tripId) {
      return newItems.filter((item) => item.orderDetails.tripId === tripId);
    }
    return newItems.filter((item) => item.orderDetails.status === "DISPATCHED");
  } else if (status === "COMPLETED") {
    return newItems.filter(
      (item) => Number(item.orderDetails.deliveredQuantity) > 0
    );
  } else {
    return null;
  }
};

// This method calculates the max quantity that can be returned for every order item based on extensions
// Also enables only disputed quantity to be editable in case any item has been disputed
const calculateReturnableQuantity = (orderItems, orderStatus) => {
  const completedStatus = orderStatus === "COMPLETED";
  const isInStoreEnabled = isExtensionEnabled("InStoreProcessing");
  const isLogisticsEnabled = isExtensionEnabled("LogisticsSupport");
  let items = orderItems ? orderItems.slice() : [];
  items.forEach((item) => {
    let orderDetails = item.orderDetails;
    let returnableQuantity = orderDetails.orderedQuantity;
    if (!orderDetails.disputeQuantity) {
      orderDetails.disputeQuantity = 0;
    }
    if (isInStoreEnabled && isLogisticsEnabled) {
      // Both logistics and instore enabled
      if (orderDetails.disputeQuantity > 0) {
        returnableQuantity = orderDetails.disputeQuantity;
        item.returnEditable = true;
      } else {
        returnableQuantity = orderDetails.deliveredQuantity;
        item.returnEditable = completedStatus;
      }
    } else if (isLogisticsEnabled && !isInStoreEnabled) {
      // Only logistics enabled
      if (orderDetails.disputeQuantity > 0) {
        returnableQuantity = orderDetails.disputeQuantity;
        item.returnEditable = true;
      } else {
        if (!isInStoreEnabled) {
          returnableQuantity = orderDetails.orderedQuantity;
          item.returnEditable = completedStatus;
        } else {
          returnableQuantity = orderDetails.deliveredQuantity;
          item.returnEditable = completedStatus;
        }
      }
    } else if (isInStoreEnabled && !isLogisticsEnabled) {
      // Only instore enabled
      if (orderDetails.disputeQuantity > 0) {
        returnableQuantity = orderDetails.disputeQuantity;
        item.returnEditable = true;
      } else {
        returnableQuantity = orderDetails.deliveredQuantity;
        item.returnEditable = completedStatus;
      }
    } else if (!isInStoreEnabled && !isLogisticsEnabled) {
      // Both logistics and in store not enabled
      if (orderDetails.disputeQuantity > 0) {
        returnableQuantity = orderDetails.disputeQuantity;
        item.returnEditable = true;
      } else {
        returnableQuantity = orderDetails.deliveredQuantity;
        item.returnEditable = completedStatus;
      }
    }
    item.returnableQuantity = returnableQuantity;
    if (!completedStatus) {
      item.isPending = true;
      item.return = false;
    }
    // If any item has to be picked up it needs to be marked as pending
    if (orderDetails.isPickupPending) {
      item.isPending = true;
    }
  });
  return items;
};

export default FileForReturnForm;
