import React, { Component } from "react";
import { Popup } from "../../../Popup";
import API from "../../../../lib/api";
import getFileExtensionFromUrl from "../../../../utils/getFileExtensionFromUrl";
import "./style.css";
import { getMessage } from "../../../../lib/translator";

function getValidationObj(props, image) {
  let valueMissing = props.required && !image;
  let result = {
    valueMissing,
    valid: !valueMissing,
  };
  return result;
}

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImagePreviewModal: false,
      fileUploading: false,
      file: "",
      fileBlob: "",
      showError: false,
      errorMessage: "",
    };
    this.readURL = this.readURL.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.confirmFileUpload = this.confirmFileUpload.bind(this);
    this.runValidation = this.runValidation.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  runValidation(image) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, image));
  }

  componentDidMount() {
    this.runValidation(this.props.value);
  }

  handleBlur(e) {
    e.preventDefault();
    this.props.onBlur && this.props.onBlur();
  }

  readURL(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      let reader = new window.FileReader();
      reader.onloadend = () => {
        let state = {
          fileBlob: reader.result,
          showImagePreviewModal: true,
          file: file,
        };
        // file validations
        if (
          file.size > this.props.maxFileSize ||
          !this.props?.accept?.split(",")?.includes(file.type)
        ) {
          state["showError"] = true;
          state["errorMessage"] =
            file.size > this.props.maxFileSize
              ? this.props.validationStrings?.fileSizeExeeded
              : this.props.validationStrings?.invalidFileType;
        } else {
          state["showError"] = false;
        }
        this.setState(state);
      };
      reader.readAsDataURL(file);
    }
  }

  handleClick() {
    this.uploadInputRef.value = null;
  }

  handleModalClose() {
    this.setState({
      showImagePreviewModal: false,
      showError: false,
    });
  }

  confirmFileUpload(e) {
    e.preventDefault();
    const { fileUpload, resolutions } = this.props;
    const file = this.state.file;
    const isVideoUpload = file.type.includes("video");

    this.setState({
      fileUploading: true,
    });

    const api = new API({ url: "/media-service/image-upload" });
    const videoApi = new API({ url: "/media-service/video-upload" });
    const fileUploadApi = new API({ url: "/media-service/file" });

    // Handle multiple resolution groups (array) or a single resolution group
    const resolutionGroups = Array.isArray(resolutions?.[0])
      ? resolutions
      : [resolutions];

    const createFormData = (resolutionGroup) => {
      let data = new window.FormData();
      data.append("imageUpload", file);

      if (this.props.resize === false) {
        data.append("resize", false);
      }
      if (resolutionGroup) {
        data.append("resolutions", JSON.stringify(resolutionGroup));
      }
      return data;
    };

    let data = new window.FormData();
    if (fileUpload) {
      data.append("fileUpload", file);
    } else if (isVideoUpload) {
      data.append("videoUpload", file);
    } else {
      data.append("imageUpload", file);
    }
    if (this.props.resize === false) {
      data.append("resize", false);
    }

    // Function to handle API error
    const handleError = (error) => {
      if (error.code === 401) {
        throw error;
      }
      this.setState({
        showError: true,
        errorMessage: error.message ?? "Error while uploading file",
        fileUploading: false,
      });
    };

    const resetState = () => {
      this.setState({
        showImagePreviewModal: false,
        showError: false,
        fileUploading: false,
      });
    };

    if (fileUpload) {
      fileUploadApi.post(data).then((response) => {
        this.props.onChange(response?.data);
        resetState();
        this.runValidation(response?.data?.accessUrl);
      }, handleError);
    } else if (isVideoUpload) {
      videoApi.post(data).then((response) => {
        const res = response?.data?.videoUrl?.url || "";
        this.props.onChange(res);
        resetState();
        this.runValidation(res);
      }, handleError);
    } else if (resolutions) {
      const uploadPromises = resolutionGroups?.map((resolutionGroup) =>
        api.post(createFormData(resolutionGroup))
      );

      Promise.all(uploadPromises)
        .then((responses) => {
          const successResults = responses.map((response) =>
            resolutions ? response.data : response?.data?.imageUpload?.url
          );

          const data = {
            imageUpload: {
              url: successResults[0]?.imageUpload?.url ?? successResults?.[0],
              resizedUrls: {
                ...(successResults[0]?.imageUpload?.resizedUrls ?? null),
                ...(successResults[1]?.imageUpload?.resizedUrls ?? null),
              },
            },
          };
          this.props.onChange(data);
          resetState();
          this.runValidation(successResults.length ? successResults : null);
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      api.post(data).then(
        (response) => {
          const res = response?.data?.imageUpload?.url || response?.data?.url;
          this.props.onChange(res);
          resetState();
          this.runValidation(res);
        },
        (error) => {
          handleError(error);
        }
      );
    }
  }

  render() {
    const {
      name,
      required,
      placeholder,
      value,
      accept,
      fileUpload,
      resolutions,
      showCloseIcon,
      handleRemove,
    } = this.props;
    const uploadedImageUrl = resolutions
      ? value?.imageUpload?.url ?? value ?? ""
      : value?.accessUrl ?? value?.imageUpload?.url ?? value ?? "";
    const isVideo = this.state?.file?.type?.includes("video");
    const previewBlob = this.state?.fileBlob;
    return (
      <div className="Upload">
        <Popup
          heading="Confirm File"
          show={this.state.showImagePreviewModal}
          close={this.handleModalClose}
        >
          {this.state.showError && (
            <div className="imageModal form-error">
              {this.state.errorMessage}
            </div>
          )}
          {fileUpload && this.state.file.type === "application/pdf" ? (
            <embed className="pdf-preview-popup" src={previewBlob} />
          ) : isVideo ? (
            <video className="video-preview-popup" src={previewBlob} controls />
          ) : (
            <img
              className="image-preview-popup"
              id={name}
              src={previewBlob}
              alt=""
            />
          )}
          <div className="actions">
            <button
              className="button"
              onClick={this.handleModalClose}
              disabled={this.state.fileUploading}
            >
              Close
            </button>
            <button
              className="primary button"
              onClick={this.confirmFileUpload}
              disabled={this.state.fileUploading || this.state.showError}
            >
              {this.state.fileUploading ? "..." : "Confirm"}
            </button>
          </div>
        </Popup>
        <div className="input">
          <input
            type="file"
            id={name}
            name={name}
            required={required}
            onChange={this.readURL}
            accept={accept}
            ref={(node) => {
              this.uploadInputRef = node;
            }}
            onClick={this.handleClick}
          />
          <div className="image-upload-div">
            {uploadedImageUrl ? (
              getFileExtensionFromUrl(uploadedImageUrl) === "pdf" ? (
                <embed
                  className="pdf-uploaded-preview"
                  src={uploadedImageUrl}
                />
              ) : isVideo ? (
                <video
                  className="video-preview-popup"
                  src={previewBlob}
                  controls
                />
              ) : (
                <img
                  className="image-preview-popup"
                  id={name}
                  src={uploadedImageUrl}
                  alt=""
                />
              )
            ) : (
              <div className="file-label">
                {placeholder || "Click here to upload or drag your image"}
              </div>
            )}
          </div>
        </div>
        {Boolean(showCloseIcon) && (
          <div className="delete-icon" onClick={() => handleRemove(name)}>
            &times;
          </div>
        )}
      </div>
    );
  }
}

Upload.defaultProps = {
  accept:
    "image/jpeg,image/jpg,image/png,image/webp,video/mp4,video/mov,video/mkv",
  maxFileSize: "20971520",
  validationStrings: {
    fileSizeExeeded: getMessage("input.fileSizeExeeded"),
    invalidFileType: getMessage("input.invalidFileType"),
  },
};

export default Upload;
