import React from "react";

function SellersIcon(props) {
  return (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#B8B8B5">
          <circle cx="15" cy="7" r="3.5" />
          <circle cx="15.5" cy="25.5" r="1.5" />
        </g>
        <g transform="rotate(45 -19.142 21.45)" stroke="#B8B8B5">
          <circle fill="none" cx="1.5" cy="4.5" r="1.5" />
          <path d="M1.5 0v3.454" />
        </g>
        <g transform="scale(-1 1) rotate(45 -34.142 -14.763)" stroke="#B8B8B5">
          <circle fill="none" cx="1.5" cy="4.5" r="1.5" />
          <path d="M1.5 0v3.454" />
        </g>
        <path
          d="M16 13.5c1.481 0 2.826.586 3.815 1.538a5.486 5.486 0 0 1 1.639 3.246l-12.93.208a5.483 5.483 0 0 1 1.587-3.381A5.483 5.483 0 0 1 14 13.5z"
          stroke="#B8B8B5"
          fill="none"
        />
        <path stroke="#B8B8B5" d="M15.5 21v3.454" fill="none" />
      </g>
    </svg>
  );
}

export default SellersIcon;
