const data = {
  en: {
    language: "English",
    strings: {
      "days.left.for.free.trial": " Days left in your free trial.",
      "pay.one.time.activation.fee":
        "Pay a one-time activation fee and unlock Zopping for lifetime.",
      "know.more": "Know More",
      "free.trial.has.ended": "FREE TRIAL HAS ENDED",
      "account.disable.notification.dialog.title": "Account Disabled",
      "account.disable.notification.dialog.information":
        "Your account with Zopping is temporarily disabled due to negative account balance. Please recharge your account to continue using our services",
      "account.disable.notification.button.text": "Recharge",
      "404.heading": "Work in Progress",
      "unexpected.error.heading": "Sorry!",
      "unexpected.error.description": "Something unexpected has happened",
      "back.text": "Back",

      "analytics.report.modal.title": "Download Report",
      "analytics.report.processing": "Processing",
      "analytics.report.send.email": "Send to Email",
      "analytics.report.recent": "Recent Reports",
      "analytics.report.dateRange": "Select Date Range",
      "analytics.report.title.reports": "Reports",
      "analytics.report.title.orderanalysis": "Order Analytics",

      "productUpload.csvDownloadText": "Download sample CSV",
      "productUpload.uploadFileText": "Click here to upload the file",
      "productUpload.addProduct": "Add Product",
      "productUpload.addBundle": "Add Bundle",
      "productUpload.orText": "OR",
      "fileUpload.importCsv.heading": "Import CSV file",
      "fileUpload.importedCsv.heading": "Upload Now",
      "fileUpload.uploadedCsv.heading":
        "CSV File Uploaded Successfully! Products will appear after 15 minutes",
      "fileUpload.importKML.heading": "Import KML file",
      "fileUpload.uploadedKML.heading": "KML File Uploaded Successfully!",
      "name.heading.text": "Name",
      "category.form.name.placeholder": "Category Name",
      "category.form.tagline.heading": "tag line",
      "category.form.tagline.placeholder": "Enter Tag Line",
      "category.form.parentCategory.heading": "Parent Category",
      "category.form.parentCategory.placeholder": "Select Parent Category",
      "category.form.description.placeholder": "Type Category Description",
      "category.form.edit.heading": "Edit Category",
      "category.form.add.heading": "Add Category",
      "category.form.uploadImage.heading": "Upload Image",
      "category.form.hideText": "Hide",
      "category.form.enableText": "Enable",
      "category.header.productCount": "Product Count",
      "category.header.status": "Status",
      "category.header.image": "Image",
      "tag.header.upload": "File",
      "actions.text": "Actions",
      "action.edit.text": "Edit",
      "category.action.seeProducts": "See Products",
      "category.helpItems.title": "From the blog",
      "category.helpItems.ppo.title": "Principles of product categorization",
      "category.helpItems.ppo.description":
        "Websites with helpful description get free visitors from search engines like Google.",
      "category.helpItems.poc.title": "Pitfalls of over-categorization",
      "category.helpItems.poc.description":
        "Images improves your landing page appeal which directly correlates with better conversion.",
      "category.helpItems.seo.title": "SEO impact of product categorization",
      "category.helpItems.seo.description":
        "Content marketing is a form of marketing focused on creating, publishing and distributing content for a targeted audience online.",
      "category.tree.title": "Category Tree",
      "category.icons.tree-title": "Tree view",
      "category.icons.table-title": "Table view",
      "category.helpItems.message": "Your categories will be shown here.",
      "category.helpItems.submessage":
        "Proper categorization improves customer’s experience.",
      "search.by.name.text": "Search by Name",
      "search.label.text": "Search",
      "category.filters.clearFiltersText": "Clear All",
      "all.label.text": "All",
      "verified.label.text": "Verified",
      "not.verified.label.text": "Not Verified",
      "credit.label.text": "Credit",
      "debit.label.text": "Debit",
      "status.label.enabled": "Enabled",
      "status.label.hidden": "Hidden",
      "are.you.sure": "Are you sure?",
      "category.delete.confirm": "The category will be deleted",
      "add.label.text": "Add",
      "category.manage": "Manage Categories",
      "category.view": "View categories",
      "navigation.sequencing.heading": "Navigation Sequencing",
      "navigation.add.button": "+ Add",
      "navigation.nosequence.display": "No Sequence to display",
      "navigation.dropdown.item.addLink": "Add Link",
      "navigation.dropdown.item.addTag": "Add Tag",
      "delivery.dashboard.empty.message":
        "Currently there are no orders that need to be delivered.",
      "analytics.dashboard.empty.message":
        "Currently there are no analytics reports to show.",

      "brand.add.text": "Add Brand",
      "brand.edit.text": "Edit Brand",
      "brand.helpItems.message": "Your brands will be shown here.",
      "brand.header.logo": "Logo",
      "brand.header.brand": "Brand",
      "brand.status.hidden": "Hidden",
      "brand.form.name.placeholder": "Enter brand name",
      "brand.form.tagline.heading": "tagline",
      "brand.form.image.placeholder":
        "Click here to upload, or drag your image",
      "tag.form.image.placeholder":
        "Click here to upload, or drag your image, video",
      "brands.empty.page.message":
        "None of your products have any 'Brands' attached to them yet. Create a new Brand name and assign them to your products.",
      "product.form.variant.duplicate.error.msg":
        "Variant with this name already exists. Please add a variant with a different name.",
      "product.form.edit.heading": "Edit Product",
      "product.filters.clientId.heading": "Client Id",
      "product.filters.clientId.placeholder": "Search by Id",
      "product.filters.category.heading": "Category",
      "product.filters.category.placeholder": "Search by Category",
      "product.filters.brand.placeholder": "Search by Brand",
      "product.filters.tag.heading": "Tag",
      "product.filters.tag.placeholder": "Search by Tag",
      "product.helpItems.message":
        "You have to create a catalogue of products for customers to buy from.",
      "product.helpItems.submessage":
        "Here you can provide all information about the products including pricing and upload some nice images.",
      "product.add.single.text": "Single product",
      "product.add.bulk.text": "Bulk upload",
      "product.search.and.add.text": "Search & Add",
      "product.search.and.add.text.placeholder": "Search Products",
      "deliveryArea.kml.upload.text": "KML upload",
      "product.selectType": "SELECT TYPE",
      "product.selectStore": "SELECT STORE",
      "product.type.inventory": "Inventory Sheet",
      "product.downloadSuccessTitle": "Download {value}",
      "product.downloadErrorTitle":
        "Cannot email catalogue as your email address is not configured",
      "product.downloadOptionsTitle": "Download",
      "product.downloadSuccessMessage":
        "Your {value} data will be sent to you via email in some time",
      "product.header.primaryCategory": "Primary category",
      "product.action.view": "View",
      "product.form.name.placeholder": "Enter product name",
      "product.form.name.description":
        "Enter the name in the format 'product-name space number space kg/g/gm'",
      "product.form.brand.placeholder": "Brand name",
      "product.form.category.placeholder": "Enter category name",
      "product.category.placeholder": "Search or add categories",
      "product.form.category.requiredMessage": "This is not a valid category",
      "product.form.tag.placeholder": "Enter tag name",
      "product.form.tag.addButton": "Add new tag",
      "product.form.category.addButton": "Add new category",
      "description.heading.text": "Description",
      "product.form.description.placeholder": "Enter product description",
      "product.description.placeholder": "Describe your product here",
      "product.form.variant.addButtonText": "Add Variant",
      "product.form.variant.name.heading": "Variant name",
      "product.form.variant.name.placeholder": "Variant",
      "product.form.variant.heading": "Variants",
      "product.form.variant.image.heading": "Upload product images",
      "product.form.variant.product.properties": "Select Product Variants",
      "product.form.variant.product.properties.placeholder":
        "Eg: Size, Colour, weight",
      "product.form.variant.product.properties.description":
        "Select all the properties that you wish to add to this product.Once added, you cannot add/ remove any property",
      "product.form.variant.image.placeholder":
        "Click to upload, or drag images here",
      "product.form.variant.price.heading": "Price",
      "product.form.variant.price.placeholder": "0.00",
      "product.form.variant.discount.heading": "Discount",
      "product.form.variant.sgst.placeholder": "0",
      "product.form.variant.barcodes.heading": "Barcodes",
      "product.form.variant.barcodes.placeholder": "Add Barcodes",
      "product.form.enter.barcodes.placeholder": "Enter barcode(s)",
      "product.form.variant.availability.heading": "Availability",
      "product.form.variant.unlimitedAvailable.label": "Unlimited",
      "product.form.variant.stock.quantity.heading": "Quantity",
      "product.form.variant.availability.label": "Available",
      "product.form.delete.message":
        "Are you sure you want to delete this product?",
      "product.form.delete.confirmText": "Confirm",
      "product.form.variant.delete.message":
        "Are you sure you want to remove this variant?",
      "product.form.all.variants.delete.message":
        "Are you sure you want to remove all variants?",
      "product.form.stores.fetchError":
        "Failed to load some stores. Click here to try again.",
      "product.form.variant.rack.heading": "Rack",
      "product.form.variant.aisle.heading": "Aisle",
      "product.form.variant.shelf.heading": "Shelf",
      "position.text.label": "Position",
      "product.form.soldByWeight": "Sold by weight",
      "product.form.handlingDays.placeholder": "Enter pre-order handling days",
      "product.form.handlingDays.label": "pre-order handling days",
      "product.form.bulkThreshold.placeholder": "Enter bulk order threshold",
      "product.form.bulkThreshold.label": "bulk order threshold",
      "product.form.soldByWeight.pattern": "Incorrect name pattern",
      "product.form.variant.pattern":
        "Enter name in the format 'number space kg/g/gm' ",
      "product.form.brand.addButton": "Add new brand",
      "product.form.enter": "Enter",
      "product.form.clientItemId": "Client Item Id",
      "product.form.clientItemId.placeholder": "Enter client item id",
      "product.form.select": "Select",
      "product.form.details.header": "Product Details",
      "product.form.other.details.heading": "Other Details",
      "product.form.variant.price.header": "Pricing",
      "product.form.variant.tax.header": "Tax",
      "product.form.variant.location.header": "Location",
      "product.form.variant.stock.header": "Stock",
      "product.form.imageUpload.uploading": "Uploading...",
      "product.form.imageUploade.error": "Error in uploading",
      "product.form.secondarycategory.heading": "Secondary categories",
      "product.form.secondarycategory.placeholder":
        "Enter secondary categories",
      "product.form.discount.error": "Discount cannot be greater than price",

      "tag.edit.text": "Edit Tag",
      "tag.helpItems.message": "Your product tags will appear here",
      "tag.action.linkToProduct": "Link to products",
      "tag.action.viewOnWebsite": "View on Website",
      "tag.action.linkToProduct.heading": "Add '{name}' tag",
      "tag.linkToProduct.product.heading": "Product",
      "tag.linkToProduct.product.placeholder": "Enter a product name",
      "tag.linkToProduct.product.action.remove": "Remove",
      "tag.linkToProduct.product.action.retry": "Retry",
      "tag.filter.clear": "Clear All ",
      "tag.import": "Import Tags",

      "substitutionGroup.add.text": "Add group",
      "substitutionGroup.helpItems.message":
        "A substitution group includes products that are similar to each other and may act as replacements when required.",
      "substitutionGroup.form.name.placeholder": "Name this substitution group",
      "substitutionGroup.form.percentageDeviation.heading":
        "Percentage Deviation",
      "substitutionGroup.form.percentageDeviation.placeholder":
        "Enter a value between 0 and 100",
      "substitutionGroup.form.product.placeholder": "Enter name of a product",
      "substitutionGroup.form.product.requiredMessage":
        "A substitution group must have at least one product",
      "substitutionGroup.action.disable": "Disable",
      "substitutionGroup.dialogs.disable.message":
        "This action will disable the substitution group",
      "substitutionGroup.dialogs.enable.message":
        "This action will enable the substitution group",

      "catalogue.dashboard.helpItems.gp.title":
        "Good Product description saves money",
      "catalogue.dashboard.helpItems.uI.title":
        "Why uploading images are important?",
      "catalogue.dashboard.helpItems.uI.description":
        "Images improves your landing page appeal which directly correlates with better conversion. ",
      "catalogue.dashboard.helpItems.cm.title":
        "Defining a content marketing stategy",
      "catalogue.dashboard.productsWithoutImages":
        "{count, plural, one{Product} other{Products} } without images",
      "catalogue.dashboard.productsWithoutDescription":
        "{count, plural, one{Product} other{Products} } without description",
      "catalogue.dashboard.categories":
        "{count, plural, one{Category} other{Categories} }",
      "catalogue.dashboard.products":
        "{count, plural, one{Product} other{Products} }",
      "catalogue.dashboard.brands":
        "{count, plural, one{Brand} other{Brands} }",

      "changePassword.different.confirmPassword":
        "Confirm password is not same as New password",
      "changePassword.oldPassword.heading": "Old Password",
      "changePassword.oldPassword.placeholder": "Enter Old Password",
      "changePassword.newPassword.heading": "New Password",
      "changePassword.newPassword.placeholder": "Enter New Password",
      "changePassword.confirmPassword.heading": "Confirm Password",
      "changePassword.confirmPassword.placeholder": "Confirm New Password",
      "changePassword.list.heading": "Password Must Contain",
      "changePassword.list.characterLimit": "At least 6 characters",
      "changePassword.list.uppercase": "At least 1 upper case letter (A..Z)",
      "changePassword.list.lowercase": "At least 1 lower case letter (a..z)",
      "changePassword.list.number": "At least 1 number (0..9)",
      "changePassword.save.success.title": "Password successfully changed",

      "billingInformation.store.name": "Billing Store Name",
      "billingInformation.address": "Billing Address",
      "billingInformation.gst": "GSTN/ VAT/ PAN Number",
      "billingInformation.invoice":
        "Invoices will be generated against this name",
      "billingInformation.registered.address":
        "Your registered address comes here",
      "billingInformation.tax.identification":
        "Tax identification number for billing purposes",
      "billingInformation.save.success.title":
        "Billing Information successfully saved",
      "personalInformation.currency": "Currency",
      "personalInformation.verifyEmail": "Verify",
      "personalInformation.save.success.title":
        "Personal Information successfully changed",
      "personalInformation.email.verifying": "Verifying...",
      "personalInformation.email.explanation":
        "Customer, order and store related communications will be mailed here",
      "personalInformation.phoneNumber.explanation":
        "Customer, order and store related communications will be sent here",
      "personalInformation.country.readonly": `Please write to support@zopping.com, if you wish to change
        'Country' for your account.`,

      "customerSupport.search.noCustomer": "No customer found",
      "customerSupport.search.noOrder": "No order found",
      "order.action.fileDispute": "File dispute",
      "calls.action.success.info": "Added Successfully",
      "calls.action.form.heading": "Add Reason & Summary",
      "type.text.label": "Type",
      "calls.table.heading.from": "From",
      "calls.table.heading.to": "To",
      "calls.table.heading.startTime": "Start Time",
      "calls.table.heading.reason": "Reason",
      "calls.table.heading.recording": "Recording",
      "calls.emptyState.text": "No Calls Found",
      "calls.form.reason.placeholder": "Enter Call Reason",
      "calls.form.summary.label": "Summary",
      "calls.form.summary.placeholder": "Enter call summary",
      "calls.filters.phone.placeholder": "Enter Phone number",
      "calls.filters.employee.label": "Employee",
      "calls.filters.employee.placeholder": "Enter Name",
      "calls.filters.customer.label": "Customer",
      "calls.filters.type.option.inbound": "Inbound",
      "calls.filters.type.option.outbound": "Outbound",
      "calls.filters.status.option.picked": "Picked",
      "calls.filters.status.option.missed": "Missed",
      "calls.filters.tag.option.cs": "CS",
      "calls.filters.tag.option.store": "Store",
      "calls.filters.tag.option.others": "Others",

      "error.Unauthorized": "Unauthorized",
      "error.generic": "Something Went Wrong!",
      "error.server":
        "Something unexpected has occured. Please try again later.",
      "error.server.403":
        "You do not have sufficient permissions to view this page.",

      "designations.empty.message": "There are no designations",
      "designations.header.timingType": "Timing Type",
      "designations.header.timing": "Timing",
      "designations.header.manager": "Manager",
      "designations.view.employees": "View employees",
      "designations.add": "Add Designation",
      "designations.form.manager.placeholder": "Enter manager designation",
      "designations.form.timingtype.fixed": "Fixed",
      "designations.form.timingtype.flexible": "Flexible",
      "designations.editheader": "Edit designation",
      "designations.form.roles": "Mapped to roles",
      "designations.form.roles.placeholder": "Enter roles",

      "attendance.label.notCheckedIn": "Not Checked In",
      "attendance.label.noDesignation": "No Designation",
      "attendance.summary.empty.message": "No attendance detail found",
      "attendance.summary.table.employee.name": "Employee Name",
      "attendance.summary.table.na": "N/A",
      "attendance.summary.table.PRESENT": "Present",
      "attendance.summary.table.WEEKLY_OFF": "Weekly Off",
      "attendance.summary.table.ABSENT": "Absent",
      "attendance.summary.table.HALFDAY": "Halfday",
      "attendance.summary.filter.form.employee.placeholder":
        "Enter an employee name",

      "shifts.header.offdaysAndStores": "Weekly off / Stores",
      "shifts.header.timingsAndAttendance": "Timings / Attendance",
      "shifts.header.blankHeading": " ",
      "shifts.header.timings": "Timings",
      "shifts.header.attendance": "Attendance",
      "shifts.designation": "Designation",
      "phone.label.text": "Phone",
      "employee.add": "Add Employee",
      "email.title.text": "Email",
      "shifts.form.email.placeholder": "Enter email ID",
      "shifts.form.toolAccess": "Allow employee to access the dashboard portal",
      "shifts.form.weekly.off.placeholder": "Select weekly offs",
      "shifts.resetpassword": "Reset password",
      "shifts.reset.title": "Reset password for",
      "success.text.title": "Success",
      "shifts.reset.success.information":
        "Password has been successfully reset",
      "shifts.confirmpassword.placeholder": "Enter the password again",
      "shifts.passwords.dont.match": "Passwords do not match",
      Monday: "Mon",
      Tuesday: "Tue",
      Wednesday: "Wed",
      Thursday: "Thu",
      Friday: "Fri",
      Saturday: "Sat",
      Sunday: "Sun",
      "Monday.all": " All Mondays",
      "Tuesday.all": "All Tuesdays",
      "Wednesday.all": "All Wednesdays",
      "Thursday.all": "All Thursdays",
      "Friday.all": "All Fridays",
      "Saturday.all": "All Saturdays",
      "Sunday.all": "All Sundays",
      "is.owner": "Owner",

      "role.edit.heading": "Edit Role",
      "role.add": "Add Role",
      "role.action": "Action",

      "deleteDialog.information": "This action cannot be reverted",

      "errorDialog.title": "Oops!",
      "errorDialog.delete.error.title": "Can not delete",

      "pagination.text": "Showing",
      "pagination.helperText": "of",

      "login.heading": "Welcome to Zopping",
      "login.subheading": "Enter your details below",
      "login.email.phone.heading": "Email or Phone number",
      "login.email.phone.placeholder": "Enter your email or phone number",
      "login.username.heading": "Username",
      "login.organisation.heading": "Organisation",
      "login.password.heading": "Password",
      "login.email.heading": "Email address",
      "login.email.placeholder": "Enter your email address",
      "login.remember": "Remember me",
      "login.forgotPassword": "Forgot Password",
      "login.text": "Login",
      "login.newUser.message": "Don't have an account?",
      "login.newUser.signupText": "Create Account",
      "login.newUser.sentMessage":
        "We will send a password to this email address or phone number",
      "login.newUser.sentMessage.on.email":
        "We will send a password to this email address",
      "login.newUser.sentMessage.on.phone":
        "We will send a password to this phone number",
      "vehicles.emptyState.helperText":
        "Here you will see a list of delivery vehicles available to you.",
      "vehicles.header.addText": "Add Vehicle",
      "vehicles.header.editText": "Edit Vehicle",
      "vehicles.header.number": "Number",
      "vehicles.header.vendor": "Vendor",
      "vehicles.heading": "Manage Vehicles",
      "vehicles.type.2w": "2 Wheeler",
      "vehicles.type.4w": "4 Wheeler",
      "vehicles.type.drone": "Drone",
      "vehicles.filters.byNumber.placeholder": "Search by number",
      "vehicles.filters.byVendor.placeholder": "Search by vendor",
      "vehicles.form.number.placeholder": "Enter vehicle number",
      "vehicles.form.vendor.addButton": "Add new vendor",
      "vehicles.form.vendor.placeholder": "Enter vendor name",
      "vehicles.vendor.addButton": "Add Vendor",
      "vehicles.vendor.heading": "Vehicle Vendors",
      "vehicles.vendor.heading.name": "Vendor Name",

      "vehiclePlanner.filters.clearFiltersText": "Clear",
      "vehiclePlanner.heading": "Vehicle Planner",
      "vehiclePlanner.emptyState.message": "No planned shift found",
      "vehiclePlanner.addShift.heading": "Add Shift",
      "vehiclePlanner.editShift.heading": "Edit Shift",
      "vehiclePlanner.checkout.button": "Check Out",
      "vehiclePlanner.replaceVehicle.popup.heading": "Replace Vehicle",
      "vehiclePlanner.vehicleList.novehicle": "No {type} vehicle checked in",
      "vehiclePlanner.checkin.form.select.plceholder": "Select vehicle",
      "vehiclePlanner.addShift.form.regular.label": "Regular Vehicle Count",
      "vehiclePlanner.addShift.form.adhoc.label": "Adhoc Vehicle Count",
      "vehiclePlanner.addShift.form.regular.count": "Enter vehicle count",
      "vehiclePlanner.replaceVehicle.form.replaced.label":
        "Vehicle to be replaced",
      "vehiclePlanner.replaceVehicle.form.replacedBy.label":
        "Vehicle to be replaced by",
      "vehiclePlanner.replaceVehicle.form.replaced.placeHolder":
        "Select the vehicle",
      "vehiclePlanner.vehicleShift.vehilceCount": "{type} Vehicle",
      "vehiclePlanner.vehicleShift.startTime": "Start Time: {time}",
      "vehiclePlanner.vehicleShift.endTime": "End Time: {time}",

      "manage-runner-executives-emptyState.emptyMessage":
        "Currently there are no runner executives ",
      "manage-runner-executives.editHeading": "Edit Runner Executive ",

      "trips.action.close": "End Trip",
      "trips.action.viewDetails": "View Details",
      "trips.endTrip.label": "End Trip KM",
      "trips.endTrip.placeholder": "Enter the KM displayed on the odometer",
      "trips.action.vehicleOdometer": "Show Odometer Image",
      "trips.emptyState.helperText":
        "There are no trips {count, plural, one{from this store} other{}}",
      "trips.filter.byDeliveryAgent.placeholder": "Search by delivery agent",
      "trips.filter.byVehicleNumber.heading": "Vehicle Number",
      "trips.filter.byVehicleNumber.placeholder": "Search by vehicle number",
      "trips.filter.byOrderNumber.heading": "Order Number",
      "trips.filter.byOrderNumber.placeholder": "Search by order number",
      "trips.filter.byRunnerExecutive.heading": "Runner Executive",
      "trips.filter.byRunnerExecutive.placeholder":
        "Search by runner executive",
      "trips.filter.byStatus.open": "Open",
      "trips.filter.byStatus.close": "Closed",
      "trips.filter.byStatus.completed": "Financially Closed",
      "trips.filter.byDates.heading": "Date",
      "trips.status.fin_close": "Financially closed",
      "trips.header.deliveryAgent": "Delivery Agent",
      "trips.header.vehicle": "Vehicle",
      "trips.header.distance": "Distance",
      "trips.header.endTime": "End Time",
      "trips.header.id": "ID",
      "trips.paymentsForm.pendingAmount": "{mode} {currency}{amount}",
      "trips.payment.order.id": "Order #",
      "trips.paymentsForm.amount.placeholder": "Amount paid by the customer",
      "trips.details.heading": "Trip #{id}",
      "trip.details.startedAt": "Started at",
      "trip.details.endedAt": "Ended at",
      "trip.details.tripIsActive": "Trip is active",
      "trips.pickuppending": "Pickup pending items",
      "trip.details.odometerImage": "Odometer Image",
      "trip.details.km": "km",
      "trip.details.orderList.singleOrder.heading": "Order",
      "trip.details.ticketList.singleOrder.heading": "Ticket",
      "trip.details.orderList.withthistrip": "with this trip",
      "trips.details.orderList.serialNumber": "S.N",
      "trips.details.orderList.completedTime": "Completed Time",
      "trips.details.orderList.referenceNumber": "Reference No",
      "trips.details.orderList.orderTime": "Order Time",
      "trips.details.orderList.amount": "Amount",
      "trips.details.orderList.preferredSlot": "Preferred Slot",
      "trips.details.orderList.transactionId": "Transaction Id",
      "trips.details.orderList.transactionId.na": "NA",
      "trips.details.orderList.view.on.map": "View On Map",
      "trips.payment.order": "Pending payments for Order",
      "trips.details.customerImage": "Customer signature",
      "trips.details.invalid-id": "Invalid Trip Id",
      "trips.startOdometer": "Trip start odometer image",
      "trip.endOdometer": "Trip end odometer image",
      "cashback.reason.option.bonus": "Bonus",
      "cashback.reason.option.promotional": "Promotional",
      "cashback.reason.option.referral": "Referral",

      "tripPlanner.emptyState.helperText":
        "There are no orders to be delivered {count, plural, one{from this store} other{}}",
      "tripPlanner.orderCount":
        "{count, plural, =0{No Orders} one{1 Order} other{# Orders}}",
      "tripPlanner.unassignedOrders.placeholder":
        "Drop orders here that are not ready for a trip",
      "tripPlanner.unassignedOrders.title": "Orders not ready for trips",
      "tripPlanner.start": "Start",
      "tripPlanner.assign": "Assign To",
      "tripPlanner.runner.executive": "Runner Executives",
      "tripPlanner.trip.started": "Trip started successfully",
      "tripPlanner.trip.storeLocation.error":
        "Store must have proper latitude-longitude.",
      "tripPlanner.table.heading": "Trip Summary",
      "tripPlanner.items": "Items count",
      "tripPlanner.orderPlacedTime": "Placed at",
      "tripPlanner.orderPlacedDate": "Created At",
      "tripPlanner.slots.startTime": "Slot Start",
      "tripPlanner.slot.endTime": "Slot End",
      "tripPlanner.order.lat": "Latitude",
      "tripPlanner.order.lng": "Longitude",
      "tripPlanner.order.distance": "Distance(km)",
      "tripPlanner.picker.name": "Picker",
      "tripPlanner.picker.startTime": "Picking start",
      "tripPlanner.picker.endTime": "Picking end",
      "tripPlanner.orderSeq": "Sequence",
      "tripPlanner.arrival": "ETA",
      "tripPlanner.waiting": "Wait",
      "tripPlanner.complete": "Completion",
      "tripPlanner.vehicle.shiftStart": "Vehicle Shift Start",
      "tripPlanner.vehicle.shiftEnd": "Vehicle Shift End",
      "tripPlanner.tripNo": "Trip Number",
      "tripPlanner.tripStart": "Trip Start",
      "tripPlanner.tripEnd": "Trip End",
      "tripPlanner.download.summary.text": "Download CSV",

      "logistics.config.heading": "Logistics Settings",
      "logistics.config.reseesOrders": "How does the RE see the orders?",
      "logistics.config.resees.placeholder": "Select one option",
      "logistics.config.allOrders": "All orders at a time",
      "logistics.config.leaveAtDoorstep": "Can RE leave the order at doorstep?",
      "logistics.config.yes": "Yes",
      "logistics.config.no": "No",
      "logistics.config.deliveryFee": "Delivery Fees",
      "logistics.config.deliveryFee.placeholder": "Enter delivery fees",
      "logistics.config.tripGeneration": "Trip generation method",
      "logistics.config.tripGeneration.placeholder":
        "Select the trip generation method",
      "logistics.config.orderBreakingMethod": "Order breaking method",
      "logistics.config.orderBreakingMethod.placeholder":
        "Select the order breaking method",
      "logistics.config.tripGeneration.automated": "Automated",
      "logistics.config.tripGeneration.basic": "Basic",
      "logistics.config.orderBreakingMethod.clustering": "Clustering",
      "logistics.config.orderBreakingMethod.zoning": "Zoning",
      "logistics.config.shouldCaptureOdometer":
        "Should the RE capture the odometer image?",
      "logistics.config.pickupFee": "Pickup Fees",

      "coupon.add.text": "Add Coupons",
      "coupon.helpItems.message": "There are no coupons shown here",
      "coupon.header.coupon": "Coupon",
      "coupon.header.limit": "Limit",
      "coupon.header.validity": "Validity",
      "coupon.action.usageAnalytic": "Usages Analytic",
      "coupon.form.edit.heading": "Edit Coupons",
      "coupon.form.name.heading": "Coupon Code",
      "coupon.form.cashback": "Cashback",
      "coupon.form.comment.heading": "Comment",
      "coupon.form.maxSingleRedemption.heading":
        "Max. Redemption By Single User",
      "coupon.form.maxRedemption.heading": "Max. Redemption",
      "coupon.form.minOrderAmount.heading": "Min. Order Amount",
      "coupon.form.validOnUser.heading": "Users",
      "coupon.form.newUsers": "New",
      "coupon.form.validOnPayment.heading": "Payment Mode",
      "coupon.form.onlinePayments": "Online",
      "coupon.form.codPayments": "COD",
      "coupon.form.comment.description": "(for internal reference only)",
      "coupon.form.discount.heading": "Coupon Gives",
      "coupon.form.limits.heading": "Limits",
      "coupon.form.conditions.heading": "Conditions",
      "coupon.form.days.heading": "Days",
      "coupon.form.startDate.placeholder": "Start Date",
      "coupon.form.endDate.placeholder": "Expiry Date",
      "coupon.form.maxDiscountAmount.heading": "Max. Discount Amount",
      "coupon.form.stores.heading": "Applies To",
      "coupon.form.stores.placeholder": "Select Stores",
      "coupon.dialog.title": "Are You sure",
      "coupon.disableDialog.information": "You want to disable this coupon",
      "coupon.enableDialog.information": "You want to enable this coupon",
      "coupon.filters.couponCode.placeholder": "Search by Coupon Code",
      "coupon.download.form.error":
        "Could not find coupons for given prefix and suffix",
      "coupon.promocode.label": "Promocode",
      "coupon.promocode.placeholder": "Enter Promocode",

      "sellers.seller.table.action.name": "Name",
      "sellers.seller.table.action.contact": "Contact",
      "sellers.seller.table.action.address": "Address",
      "sellers.seller.table.action.status": "Status",
      "sellers.seller.table.action.actions": "Actions",
      "sellers.seller.helpItems.message": "No Seller Yet",
      "sellers.seller.form.edit.heading": "Edit Seller",
      "sellers.seller.form.add.heading": "Add Seller",
      "sellers.seller.form.title.heading": "SELLER NAME",
      "sellers.seller.form.title.placeholder": "Enter seller name",
      "sellers.seller.form.title.requiredMessage":
        "Your seller must have a name",
      "sellers.seller.form.phone.heading": "Support Phone",
      "sellers.seller.form.customer.phone.heading": "Customer Phone Number",
      "sellers.seller.form.phone.placeholder": "Enter Phone",
      "sellers.seller.form.email.heading": "Support Email",
      "sellers.seller.form.email.placeholder": "Enter Email",
      "sellers.seller.form.address.heading": "Complete Address",
      "sellers.seller.form.address.placeholder": "Enter Your Address",
      "sellers.seller.form.description.title.heading": "Description",
      "sellers.seller.form.description.title.pleaceholder":
        "Like Your Vision Mission etc",
      "sellers.seller.form.image.logo.heading": "LOGO",
      "sellers.confirm.text": "Confirm",
      "sellers.cancel.text": "Cancel",
      "seller.status.active": "Activate",
      "seller.status.inactive": "Inactivate",
      "seller.status.edit": "Edit",
      "seller.status.delete": "Delete",
      "sellers.user.password.heading": "password",
      "sellers.user.password.placeholder": "Enter Password",
      "sellers.user.add.text": "Add User",
      "sellers.user.add.nouser.text": "No User Yet",
      "sellers.user.edit.text": "Edit User",
      "sellers.user.form.name.heading": "Name",
      "sellers.user.form.name.pleceholder": "Enter Name",
      "sellers.user.form.sellername.heading": "Seller Name",
      "sellers.user.form.sellername.placeholder": "Enter Seller Name",
      "sellers.user.form.phone.heading": "Phone Number",
      "sellers.user.form.phone.placeholder": "Enter Phone Number",
      "sellers.user.form.email.heading": "Email",
      "sellers.user.form.email.placeholder": "Enter Email",
      "sellers.user.table.name": "Name",
      "sellers.user.table.sellername": "Seller Name",
      "sellers.user.table.email": "Email id",
      "sellers.user.table.action": "Actions",
      "user.action.edit": "Edit",
      "user.action.delete": "Delete",
      "user.action.reset.password": "Reset Password",
      "users.save.text": "Save",
      "seller.user.edit.text": "Edit User",
      "users.reset.title": "Reset password for",
      "users.reset.success.title": "Success",
      "users.reset.success.information": "Password has been successfully reset",
      "users.reset": "Okay",

      "marketing.blog.add.text": "Write a new post",
      "marketing.blog.helpItems.message": "There are no blogs shown here",
      "marketing.form.add.heading": "Add blog post",
      "marketing.form.edit.heading": "Edit blog post",
      "marketing.blog.form.title.heading": "Post Title",
      "marketing.blog.form.title.requiredMessage":
        "Your blog must have a title",
      "marketing.blog.form.content.placeholder": "Type here",
      "marketing.blog.form.submitText": "Publish",
      "title.heading.text": "Title",
      "marketing.table.heading.publishTime": "Published at",
      "marketing.table.heading.author": "Written by",
      "marketing.table.heading.interaction": "Interaction",
      "marketing.blog.table.status.draft": "Draft",
      "marketing.blog.table.action.unpublish": "Unpublish",
      "marketing.blog.dialogs.publish.message":
        "This will publish the blog on the website",
      "marketing.blog.dialogs.unpublish.message":
        "This will remove the blog on the website and add it to drafts",

      "websitePage.table.heading": "Static Pages",
      "websitePage.add.text": "Add page",
      "websitePage.emptyList.message":
        "There are no static pages for your website",
      "websitePage.table.url": "URL",
      "websitePage.dialogs.disable.message":
        "This action will disable this page",
      "websitePage.dialogs.enable.message": "This action will enable this page",
      "websitePage.form.add.heading": "Add static page",
      "websitePage.form.edit.heading": "Edit static page",
      "websitePage.form.title.heading": "Page Title",
      "websitePage.form.title.placeholder":
        "Please enter the title of your page",
      "websitePage.form.url.placeholder": "URL of the page",
      "websitePage.form.title.requiredMessage": "Your page must have a title",
      "websitePage.form.content.placeholder":
        "What you type here will be shown as the content of this page on your website",
      "websitepage.form.addLayout": "Add Layout",
      "websitePage.dialog.information": "This layout will be deleted",
      "websitePage.popup.imagewithtext.heading": "Edit image with text",
      "imagewithtext.placeholder": "Enter title",
      "websitePage.popup.heading.image": "Edit Image",
      "marketing.blog.form.title.placeholder": "Enter your post title",
      "marketing.blog.form.url.placeholder": "URL of the blog",
      "marketing.blog.form.meta.description.title.heading": "Meta Description",
      "marketing.blog.form.meta.description.title.placeholder":
        "Write a brief summary of your post",
      "marketing.blog.form.featured.image.title.heading": "Featured Image",
      "marketing.blog.form.assign.tag.placeholder":
        "Search or create a new tag",
      "marketing.blog.form.assign.tag.title.heading": "Assign Tag",
      "marketing.blog.form.alt.text.title.heading": "Alt Text",
      "marketing.blog.form.alt.text.title.placeholder":
        "Enter the alt text for the above featured image",
      "campaign.form.add.heading": "New Campaign",
      "campaign.form.edit.heading": "Edit Campaign",
      "campaign.add.text": "Add Campaign",
      "campaign.helpItems.message": "There are no campaigns",
      "campaign.table.heading.runDate": "Run Date",
      "campaign.form.emailSubject.heading": "Subject",
      "campaign.form.emailBody.heading": "Body",
      "campaign.form.sms.heading": "SMS",
      "campaign.form.pushSubject.heading": "NOTIFICATION TITLE",
      "campaign.form.pushSubject.placeholder": "Enter title here",
      "campaign.form.pushText.heading": "NOTIFICATION TEXT",
      "campaign.form.pushText.placeholder": "Enter text here",
      "campaign.form.pushUrl.placeholder": "Enter url here",
      "campaign.form.pushImage.heading": "ADD NOTIFICATION IMAGE",
      "campaign.form.pushImage.placeholder": "Add an image",
      "campaign.form.time.heading": "Time",
      "campaign.form.time.am": "AM",
      "campaign.form.time.pm": "PM",
      "campaign.form.preview.heading": "Preview",
      "campaign.form.maxcharacters.description": "Max. 150 characters",
      "campaign.form.vairableList.heading": "List of available variables",
      "campaign.form.invalidDateTime":
        "Datetime should be greater than current datetime",

      "offer.product.placeholder": "Select products",
      "offer.category.placeholder": "Select category",
      "offer.categories.placeholder": "Select Categories",
      "offer.brand.placeholder": "Select brand",
      "offer.brands.placeholder": "Select brands",
      "offer.entity": "Entity Type",
      "offer.entity.id": "Entity ID",
      "offer.entityid.placeholder": "Enter Entity ID",
      "offer.validFrom": "Valid From",
      "offer.validTill": "Valid Till",
      "offer.add.text": "Add Offer",
      "offer.type.placeholder": "Select offer type",
      "offer.applicable.to.stores": "Applicable to store",
      "offer.edit.heading": "Edit offer",
      "offer.alert.message": "Alert Message",
      "offer.alert.message.placeholder": "Enter alert message",
      "offer.max.redemption": "order level redemption",
      "offer.max.redemption.placeholder": "Enter order level redemption",
      "offer.customer.redemption": "Customer Redemption",
      "offer.customer.redemption.placeholder": "Enter customer redemption",
      "offer.total.redemption": "Total Redemption",
      "offer.total.redemption.placeholder": "Enter total redemption",
      "offer.delivery-date.end": "End Date",
      "offer.ordertype.label": "Order Type",
      "offer.ordertype.placeholder": "Select order type",
      "offer.ordertype.options.bulk": "Bulk",
      "offer.ordertype.options.pickup": "Pickup",
      "offer.ordertype.options.offline": "Offline",
      "offer.description.placeholder": "Enter description",
      "offer.percentoff": "Percent off",
      "offer.absoluteoff": "Absolute off",
      "offer.all.stores": "All stores",
      "offer.selected.stores": "Selected stores",
      "offer.all.except.selected.stores": "All except selected stores",
      "offer.quantity.placeholder": "Enter quantity",
      "offer.discounttype": "Discount type",
      "offer.discounttype.placeholder": "Select discount type",
      "offer.discountvalue": "Discount value",
      "offer.maxdiscountvalue": "Max Discount",
      "offer.discountvalue.placeholder": "00",
      "offer.min.quantity": "Min quantity",
      "offer.min.quantity.placeholder": "Enter min quantity",
      "offer.time": "Valid from time",
      "offer.time.placeholder": "Select time",
      "offer.validto.time": "Valid till time",
      "offer.combo.discount": "Combo",
      "offer.individual.discount": "Individual",
      "offer.get": "Get",
      "offer.main.discount.type": "Type of discount",
      "offer.cartprice": "Cart Price",
      "offer.cartprice.placeholder": "Enter cart price",
      "offer.emptyMessage": "There are no offers",
      "offer.includeText": "to include",
      "offer.excludeText": "to exclude",
      "offer.min.amount.label": "Min Amount",
      "offer.min.amount.placeholder": "Enter min amount",
      "offer.includes": "includes",
      "offer.min.amount": "Minimum Amount",
      "offer.payment.type": "Payment type",
      "offer.payment.type.placeholder": "Enter payment type",
      "offer.buy": "Buy",
      "offer.filter.clientId.placeholder": "Search by Client Id",
      "offer.heading.deliveyDate": "Delivery Date",
      "offer.dialog.notification.label": "Notification",
      "offer.dialog.notification.message":
        "Promocodes has been sent to your email address",
      "offer.dialog.notification.close": "Close",
      "offer.field.hasUniquePromocode.label": "Has Unique Promocode",
      "offer.field.stackable.label": "Stackable",
      "offer.dialog.clone.label": "Warning",
      "offer.dialog.clone.message":
        "This offer will be disabled and a new offer will be created with the remaining redemption",
      "offer.dialog.clone.proceed": "Continue",
      "offer.dialog.disable.errorText":
        "Error occurred while disabling the offer. Please try agin.",
      "offer.clone.disableNClone": "Disable and create new",
      "offer.clone.copy": "Create similar",
      "offer.redeemedCount.placeholder":
        "Redeemed count of original offer: {count}",

      "banners.header": "Manage Banners",
      "banners.select.page.type": "Page Type",
      "banners.select.page.type.placeholder": "Select page type",
      "banners.select.search.term": "search term",
      "banners.select.search.term.placeholder": "Enter search term",
      "banners.radio.option.active": "Active banners",
      "banners.radio.option.expired": "Expired banners",
      "banners.view.drag.text": "Drag to change the order",
      "banners.view.add.button": "Add Banner",
      "banners.editForm.header": "Edit Banner",
      "banners.editForm.link.label": "Link URL",
      "banners.editForm.radio.label": "Scope",
      "banners.editForm.radio.option.global": "Global",
      "banners.editForm.radio.option.storeSpecific": "Store specific",
      "banners.editForm.validity.from": "Validity from",
      "banners.editForm.validity.to": "Validity to",
      "banners.editForm.link.placeholder": "URL to navigate on clicking",
      "banners.popup.banner.selection.heading": "Choose Banner",
      "banners.page.type.placeholder": "Select the page type",
      "banners.message.invalidDate": "End date must be after the start date",
      "banners.empty.banner":
        "Currently there are no home page banners available",
      "banners.stores.placeholder": "Select the store",
      "banners.form.sequence.placeholder": "Enter the sequence",
      "banners.table.view.on.web.url": "View on web",
      "banners.table.scope": "Scope:",
      "banners.table.na": " N/A",
      "banners.form.search.term.placeholder": "Enter the search term",
      "banners.form.startTime.placeholder": "Enter Start Time",
      "banners.form.endTime.placeholder": "Enter End Time",
      "banners.success.dialog.message": "Saved",
      "banners.default.page": "Home",
      "banners.pageType.productTag": "Product_tag",
      "banners.languages": "Language",
      "banners.languages.placeholder": "Select the language",

      "coupon.analytics.heading": "Coupon Analytics",
      "coupon.analytics.analyticsFor": "Analytics For",
      "coupon.analytics.uniqueUsers.heading": "Unique Users",
      "coupon.analytics.activeFor.heading": "Active For(Days)",
      "coupon.analytics.totalDiscount.heading": "Total Coupon Discount",
      "coupon.analytics.totalPurchases.heading": "Total Sales",
      "coupon.analytics.today.heading": "Today",
      "coupon.analytics.thisWeek.heading": "This Week",
      "coupon.analytics.paymentDistribution.heading": "Payment Distribution",
      "coupon.analytics.deviceDistribution.heading": "Device Distribution",
      "coupon.analytics.dailyDistribution.heading": "Daily Distribution",
      "coupon.analytics.selectDates.heading": "Select Dates",
      "campaign.analytics.heading": "Campaign Analytics",
      "campaign.analytics.subHeading": "User Engagement",
      "campaign.header.campaign": "Campaign",
      "campaign.action.viewAnalytics": "View Analytics",
      "campaign.analytics.zopLinkActivity.heading": "ZopLink Activity",
      "campaign.analytics.zopNowMissyou.message": "[ZopNow] We Miss You !",
      "campaign.analytics.table.heading": "Last 10 Opened",
      "campaign.analytics.emailTab.table.header.dateTime": "Date & Time",
      "campaign.analytics.emailTab.table.header.promotional": "Promotional",
      "campaign.analytics.smsTab.table.header.lastVisit": "Last visit",
      "campaign.analytics.smsTab.table.header.clicks": "Clicks (unique)",
      "coupon.analytics.emailTab.basedOnEmailsDelivered":
        "Based on emails delivered",
      "coupon.analytics.emailTab.basedOnEmailsOpened": "Based on emails opened",
      "coupon.analytics.emailTab.opened": "Opened",
      "coupon.analytics.emailTab.clickedThrough": "Clicked through",
      "coupon.analytics.smsTab.clicked": "Clicked",
      "coupon.analytics.smsTab.basedOnDelivered": "Based on delivered",
      "coupon.analytics.pieChart.DESKTOP_NA_WEB": "Desktop",
      "coupon.analytics.pieChart.MOBILE_ANDROID_WEB": "Android",
      "coupon.analytics.pieChart.MOBILE_IOS_WEB": "IOS",

      "menu.selectLanguage.heading": "Select language",
      "menu.item.operations": "Operations",
      "menu.item.home": "Home",
      "menu.item.dashboard": "Dashboard",
      "menu.item.stores": "Stores",
      "menu.item.customers": "Customers",
      "menu.item.orders": "Orders",
      "menu.item.support": "Support",
      "menu.item.orders-analytics": "Orders Analytics",
      "menu.item.reports": "Reports",
      "menu.item.customer-management": "Customer Management",
      "menu.item.trip-planner": "Trip Planner",
      "menu.item.logistics": "Logistics",
      "menu.item.offers": "Offers",
      "menu.item.fb-analytics": "Facebook Analytics",
      "menu.item.google-analytics": "Google Analytics",
      "menu.item.seller-support": "Sellers",
      "menu.item.sellers": "Sellers",
      "menu.item.employee": "Users",

      "menu.item.all-courier-orders": "All Courier Orders",

      "menu.item.catalogue": "Catalogue",
      "menu.item.categories": "Categories",
      "menu.item.brands": "Brands",
      "menu.item.products": "Products",
      "menu.item.tags": "Tags",
      "menu.item.substitution-groups": "Substitution Groups",
      "menu.item.marketing": "Marketing",
      "menu.item.coupons": "Coupons",
      "menu.item.blog": "Blog",
      "menu.item.pages": "Static Pages",
      "menu.item.campaigns": "Campaigns",
      "menu.item.settings": "Settings",
      "menu.item.basic-information": "Basic information",
      "menu.item.contact-details": "Contact Details",
      "menu.item.reviews": "Reviews",
      "menu.item.themes": "Website",
      "menu.item.extensions": "Extensions",
      "menu.item.seo": "SEO",
      "menu.item.users": "Multi User Support",
      "menu.item.mobile-apps": "Mobile Apps",
      "menu.item.payment-configuration": "Payments",
      "menu.item.my-account": "My Account",
      "menu.item.account-balance": "Account Balance",
      "menu.item.change-password": "Change Password",
      "menu.item.billing-information": "Billing Information",
      "menu.item.personal-information": "Personal Information",
      "menu.item.logout": "Logout",
      "menu.item.delivery-area": "Delivery Area",
      "menu.item.notifications": "Manage Notifications",
      "menu.item.website-link": "Your online store",
      "menu.item.picking-queue": "Picking Queue",
      "menu.item.vehicles": "Vehicle",
      "menu.item.vehicle-planning": "Vehicle Planning",
      "menu.item.trips": "Trips",
      "menu.item.manage-runners": "Manage Runner Executives",
      "menu.item.designations": "Designations",
      "menu.item.leave-summary": "Leave Summary",
      "menu.item.attendance-summary": "Attendance Summary",
      "menu.item.hr": "HR",
      "menu.item.leave-management": "Leave Management",
      "menu.item.employees": "Staff",
      "menu.item.my-leaves": "My Leaves",
      "menu.item.rack-management": "Rack Mangement",
      "menu.item.disputes": "Disputes",
      "menu.item.returns": "Pending Returns",
      "menu.notificationToday.empty": "There are no notification shown here",
      "menu.item.slots": "Slots",
      "menu.item.store-slot-config": "Slot Blocking",
      "menu.item.roles": "Roles",
      "menu.item.tawk": "Tawk.to Live Chat",
      "menu.item.custom-fields": "Custom Fields",
      "menu.item.analytics": "Analytics",
      "menu.item.instore": "Instore Processing",
      "menu.item.pickers": "Manage Pickers",
      "menu.item.user-hover": "user hover",
      "menu.item.user": "My account",
      "menu.item.calls": "Calls",
      "menu.item.web-banners": "Banners",
      "menu.item.google-merchant-center": "Google Merchant Center",
      "menu.item.referral-management": "Referral Management",
      "menu.item.what-more": "What More",
      "menu.item.stock-overrides": "Stock Overrides",
      "menu.item.search-configuration": "Search Overrides",
      "menu.item.communication-configuration": "Communication",
      "menu.item.customer-tags": "Customer Tags",
      "menu.item.capacity-planning": "Capacity Planning",
      "menu.item.bulk-coupons": "Bulk Coupons",
      "menu.item.slot-charges": "Slot Charges",
      "menu.item.order-config": "Order configuration",
      "menu.item.integration-settings": "Integration Settings",
      "menu.item.delete-account": "Delete account",
      "menu.item.order-package-config": "Packaging",
      "menu.item.delivery": "Delivery",
      "menu.item.delivery-area-setup": "Delivery Area Setup",
      "menu.item.delivery-support": "Delivery Support",
      "menu.item.courier-support": "Shipping",
      "menu.item.staff-management": "Staff Management",
      "menu.item.staff": "Staff",
      "menu.item.online-store": "Online Store",
      "menu.item.product-variant-support": "Product Variant",
      "menu.item.allReturns": "Returns",

      "review.order.table.header.orderid": "OrderID",
      "review.order.table.header.rating": "Rating",
      "review.order.table.header.date": "Date",
      "review.order.table.header.customer": "Customer",
      "review.order.table.header.more": "More",
      "review.all.product.table.header.item": "Item",
      "review.individual.product.table.header.average.rating":
        "Average Ratings",
      "review.individual.product.table.header.reviews": "Reviews",
      "marketing.action.REJECTED.status": "Disapprove",
      "marketing.action.APPROVED.status": "Approve",
      "choose.theme.placeholder": "Select theme",
      "choose.theme.device.placeholder": "Select device",
      "industry.selection.error.message":
        "Some error occurred while fetching Industries",
      "onboarding.basicInfo.sencondaryHeading":
        "Enter your store information below",
      "onboarding.basicInfo.storeurl.description":
        "Your store URL is provided free of cost at zopping.com. If you already own a domain",
      "onboarding.basicInfo.clickHere.text": "click here",
      "onboarding.basicInfo.useIt.text": "to use it or",
      "onboarding.industry.heading": "What is your industry",
      "onboarding.products.sencondaryHeading1":
        "Use below 10 sample products to create your",
      "onboarding.products.sencondaryHeading2": "website now and",
      "onboarding.theme.sencondaryHeading1":
        "Here is your default theme for your",
      "onboarding.theme.sencondaryHeading2": "website ",
      "onboarding.basicinfo.url.subtext": "Or use",

      "googleMaps.zeroResults.error": "Address not found",
      "googleMaps.addressMap.error":
        "Google Map API failed on Address Map with status",
      "order.filters.referenceNumber.placeholder": "Enter an order number here",
      "order.filters.status.placeholder": "Choose order status",
      "order.filters.preorder": "Preorder",
      "order.filters.enterCustomerEmail": "Enter Customer's Email",
      "order.filters.enterCustomerPhone": "Enter Customer's Phone",
      "order.emptyList.message":
        "You have not recieved any orders yet. Once your customers place their orders on your online store, they will appear here.",
      "order.helpItems.title": "How to get orders?",
      "order.helpItems.seo.title": "Search Engine Optimization",
      "order.helpItems.seo.description":
        "You will be able to target your customers using email and SMS after the import, which will potentially increase your revenue.",
      "order.helpItems.rcm.title": "Repeat Customer Marketing",
      "order.helpItems.rcm.description":
        "You can create a discount coupon for your customers and promote the online purchase.",
      "order.helpItems.nca.title": "New Customer Acquisition",
      "order.helpItems.nca.description":
        "You can put up a banner at a prominent place in your physical store. Include the promotional offer to create buzz.",
      "order.table.header.referenceNumber": "Order no.",
      "order.table.clientId.prefix": "#",
      "order.details.invoice.order": "Invoice ",
      "order.table.header.placedTime": "Placed time",
      "order.table.header.expectedTime": "Order Slot",
      "order.table.text.fillrate": "Fill Rate: ",
      "order.table.header.completionTime": "Completion time",
      "order.table.header.courierAndCharge": "Courier & Charge",
      "order.table.itemCount": "{count} Items",
      "order.table.courierName": "Courier: {courierName}",
      "order.table.courierCharge": "Courier Charges",

      "order.table.pendingAmount": "{currency}{amount} Pending",
      "order.table.refundAmount": "{currency}{amount} Refund",
      "order.gridItem.status.title": "Order status",
      "order.status.completed": "Completed",
      "order.status.cancelled": "Cancelled",
      "order.status.failed": "Failed",
      "order.status.pending": "Pending",
      "order.status.checked": "Checked",
      "order.status.packed": "Packed",
      "order.status.picked": "Picked",
      "order.status.partially-picked": "Partially Picked",
      "order.status.allorders": "All Orders",
      "order.status.inprogress": "In Progress",
      "order.action.complete": "Complete Order",
      "order.action.packed": "Pack Order",
      "order.action.cancel": "Cancel Order",
      "order.action.cancel.courier": "Cancel Courier",
      "order.action.viewInvoice": "View Invoice",
      "order.action.printInvoice": "Print Invoice",
      "order.action.viewPayments": "Payment Details",
      "order.action.return": "Return Order",
      "order.action.dispatch": "Dispatch",
      "order.status.picking": "Picking",
      "order.status.partiallypicked": "Partially picked",
      "order.status.checking": "Checking",
      "order.status.packing": "Packing",
      "order.status.dispatched": "Dispatched",
      "order.status.returned": "Returned",
      "order.details.packing.heading": "Packing Details",
      "order.packing.labels": "Labels",
      "order.packing.smallBags": "Small Bags",
      "order.packing.largeBags": "Large Bags",
      "order.packing.frozenBoxes": "Frozen Boxes",
      "order.packing.crates": "Crates",
      "order.packing.smallBoxes": "Small Boxes",
      "order.packing.mediumBoxes": "Medium Boxes",
      "order.packing.largeBoxes": "Large Boxes",
      "order.packing.weight": "Weight (in grams)",
      "order.vendor.shiprocket": "Shiprocket",
      "order.vendor.dunzo": "Dunzo",
      "order.vendor.other": "Other",
      "order.vendor.self": "Self",

      "order.details.cancellation.message":
        "This action will cancel this order",
      "order.details.courier.cancellation.message":
        "This action will cancel this courier",
      "order.details.packed.item.return.message":
        "There are returned items in the order",
      "order.details.completion.message":
        "This action will complete this order",
      "order.details.return.message": "This action will return the order",
      "order.details.pending.message":
        "This action will move the order to PENDING status",
      "order.action.moveToPending": "Move to pending",
      "order.action.editPackedOrder": "Edit Packed Order",
      "order.details.completion.confirmReturnItem": "confirm items",
      "order.details.productSearch.placeholder": "Search a product to add",
      "order.details.removeItem.title": "Remove this item?",
      "order.details.removeItem.message":
        "Press confirm to remove this item from the order",
      "order.details.itemsTable.title": "Items in order",
      "order.details.itemsTable.header.quantity": "Original Quantity",
      "order.details.itemsTable.header.picked": "Final Quantity",
      "order.details.itemsTable.header.quantity.abbreviated": "Qty",
      "order.details.itemsTable.clearingMessage":
        "All items are removed. Order will be cancelled.",
      "order.details.itemsTable.emptyMessage":
        "There are no items in this order.",
      "order.details.itemsTable.actions.confirmText": "Done",
      "order.details.itemsTable.itemsInOrder.unavailabelText":
        "Item unavailable",
      "order.details.errors.onSave": "An error occurred. Click here to retry ⟳",
      "order.details.errors.onLoad":
        "Something went wrong. Click here to try again. ⟳",
      "order.details.edit-packed.heading": "Packed Order Editing",
      "order.details.packages.multi-text.label": "Packages",
      "order.details.packages.multi-text.placeholder": "Enter package label",
      "order.details.edit-packed.error": "Something went wrong",

      "order.details.summary.taxTitle": "Tax summary",
      "order.details.summary.price": "Base Price",
      "order.details.summary.taxAmount": "Tax Amount",
      "order.details.summary.netAmount": "Net Amount",
      "order.details.summary.totalTax": "Total Tax",

      "order.details.summary.pendingAmount": "Pending amount",
      "order.details.summary.refundAmount": "Refund amount",
      "order.details.summary.totalAmount": "Total amount",
      "order.details.summary.orderAmount": "Order amount",
      "order.details.summary.coupondiscount": "Coupon discount",
      "order.details.summary.shippingCharge": "Shipping charge",

      "order.details.summary.courierDetails": "Courier Status",

      "order.details.summary.shippingDiscount": "Shipping discount",
      "order.details.summary.collectionCharge": "Collection charge",
      "order.details.summary.collectionDiscount": "Collection discount",
      "order.details.summary.slotCharge": "Slot charge",
      "order.details.summary.slotDiscount": "Slot Discount",
      "order.details.location.delivery.pending.title": "Delivering to",
      "order.details.location.delivery.completed.title": "Delivered to",
      "order.details.location.pickup.pending.title": "Pickup Details",
      "order.details.location.b2b.pending.title": "B2B Details",
      "order.details.location.offline.pending.title": "Offline Details",
      "order.details.invoice.title": "Invoice",
      "order.details.invoice.taxDisclaimer": "* All prices are tax inclusive",
      "order.details.invoice.referenceNumber": "Order #{referenceNumber}",
      "order.details.payment.mode": "Mode",
      "order.details.payment.card": "Card",
      "order.details.payment.details": "Details",
      "order.details.payment.ref": "Refund reference",
      "order.details.preferredDate": "Preferred date",
      "order.details.tomorrow": "Tomorrow",
      "order.details.pickup.address": "Pickup Address: ",
      "order.details.preorder": "Pre Ordered",
      "order.register.payments": "Register Payments",
      "order.paymentmode.LOYALTY": "Loyalty",
      "order.paymentmode.MSWIPE": "Mswipe",
      "order.paymentmode.PREPAID": "Prepaid",
      "order.register.payments.for": "Register payments for",
      "order.payment.paymentmode": "Payment Method",

      "order.add.text": "Create new order",
      "order.form.add.heading": "Place New Order",
      "order.form.customer.phone.heading": "Customer Phone Number",
      "order.form.productSearch.heading": "Search Product/Scan Barcode",
      "order.form.paymentMode.heading": "Payment Option",
      "order.form.paymentMode.cash": "Cash",
      "order.form.submit.text": "Place Order",
      "order.form.customer.name.heading": "Customer Name",
      "order.form.customer.name.placeholder": "Enter Customer Name",
      "order.form.customer.email.heading": "Customer Email Address",
      "order.form.customer.email.placeholder": "Enter Customer Email",
      "order.form.customerDetails.heading": "Enter Customer Details",
      "order.form.addProduct.heading": "Add Products",
      "order.form.pickupLocation.heading": "Pickup location",
      "order.form.customer.address.heading": "Address",
      "order.form.customer.landmark.heading": "Landmark",
      "order.form.customer.city.heading": "City",
      "order.form.customer.pincode.heading": "Pincode",
      "order.form.customer.address.line.one.heading": "Address line 1",
      "order.form.customer.address.line.two.heading": "Address line 2",
      "order.form.customer.address.placeholder": "Enter address",
      "order.form.customer.landmark.placeholder": "Enter landmark",
      "order.form.customer.pincode.placeholder": "Enter pincode",
      "order.form.customer.city.placeholder": "Enter city",
      "order.form.save.success.title": "Order placed successfully!",
      "order.form.cash.tendered": "Cash tendered",
      "order.form.balanceAmount": "Balance",
      "order.form.addAddress.heading": "Add Address",
      "order.form.deliveryArea.heading": "Select Delivery Address",
      "order.metaData.heading": "Order Custom Fields",
      "order.form.date.heading": "Select Date",
      "order.form.slot.heading": "Select Slot",
      "order.form.slots.error.message": "Select a slot",
      "order.form.addressnotserved":
        "Address not served. Please select another address or store",
      "order.payment.paid": "Paid",
      "order.payment.refund": "Refund",
      "order.filters.payment.placeholder": "Select payment mode",
      "template.name": "Template Name",
      "msg91.template.id": "MSG91 Template ID",
      "order.filters.paymentStatus": "Payment Status",
      "order.filters.paymentStatus.placeholder": "Select payment status",
      "order.filters.clientId.placeholder": "Enter client id",
      "order.filters.slot.placeholder": "Select order slot",
      "order.filters.placedOn": "Placed On",
      "order.filters.slot.fetchTitle": "Wait! Fetching slots",
      "order.filters.slot.sortBy.asc": "Earliest to Oldest",
      "order.filters.slot.sortBy.desc": "Oldest to Earliest",
      "order.filters.slot.sortBy.text": "Sort By Slot",
      "order.filters.slot.sortBy.placeholder": "Select sort by",

      "order.log.itemName": "Item Name",
      "order.log.oldQuantity": "Old Quantity",
      "order.log.newQuantity": "New Quantity",
      "order.log.orderItemId": "Order Item ID",
      "order.action.order.logs.for": "Order logs for",
      "order.actions.logs": "View logs",

      "order.actions.printLabel": "Print Label",
      "order.actions.manifest": "View Manifest",

      "order.actions.trips": "View trips",
      "order.logs.emptyMeassage": "No order logs for this order",
      "order.log.user": "by",
      "order.logs.heading": "Order Log ",
      "order.logs.orderRef": "Ref.",
      "order.log.tripId": "Trip Id ",
      "order.log.placedfrom": "Placed from",

      "order.log.payment.changed": "Payment Changed",
      "order.log.address.changed": "Address Changed",
      "order.log.shipping.charges": "Shipping Charges",
      "order.log.type.changed": "Type Changed",
      "order.log.amount.changed": "Amount Changed",
      "order.log.date.changed": "Date Changed",
      "order.log.status.changed": "Status Changed",
      "order.log.slot.changed": "Slot Changed",
      "order.log.return": "Order return",
      "order.log.payment.initiated": "Payment initiated",
      "order.log.payment.cancelled": "Payment cancelled",
      "order.log.payment.completed": "Payment completed",
      "order.log.refund.initiated": "Refund initiated",

      "order.courier.select.heading": "Dispatch by",
      "order.courier.dispatch.success":
        "Order dispatch request sent successfully!",
      "order.courier.dispatch.table.header.name": "Courier Name",
      "order.courier.dispatch.no.vendor.found": "No courier vendor found",
      "order.courier.dispatch.table.header.pickup.estimate": "Pick Up Estimate",
      "order.courier.dispatch.table.header.delivery.estimate":
        "Delivery Estimate",

      "order.dispatch.label.courier.name": "COURIER NAME",
      "order.dispatch.label.courier.charges": "Charges",
      "order.dispatch.label.courier.tracking.id": "Tracking Id",
      "order.dispatch.placeholder.courier.name": "Ex: India Post",
      "order.dispatch.placeholder.courier.charges": "₹10",
      "order.dispatch.placeholder.courier.tracking.id": "AWB87987FRS",

      //Ticketing system
      "tickets.heading.delivery.address": "Delievery Address : ",
      "ticket.filters.clearFiltersText": "Clear All",
      "ticket.details.ticketList.singleOrder.heading": "Ticket",
      "ticket.details.status": "Ticket Status : ",
      "ticket.details.heading": "Ticket Details",
      "ticket.order.details.heading.createdAt": "Ticket Created At : ",
      "ticket.order.details.heading.buyerApp": "Buyer App : ",
      "ticket.order.details.heading.order.id": "Order ID : ",
      "tickets.heading": "All Tickets",
      "ticket.status.closed": "Closed",
      "ticket.status.pending": "Pending",
      "ticket.status.open": "Open",
      "ticket.status.resolved": "Resolved",

      "customer.helpItems.message":
        "You will see your customers’ details here. Get customers now.",
      "customer.helpItems.title": "How to get Customers?",
      "customer.helpItems.iec.title": "Import your existing customers",
      "customer.helpItems.rpo.title": "Run a promotional offer",
      "customer.helpItems.om.title": "Offline Marketing",
      "customer.import.text": "Import Customers",
      "customer.import.successText":
        "CSV File Uploaded Successfully! Customers will be added and appear after 15 minutes",
      "customer.tags.import.successText":
        "CSV File Uploaded Successfully! Tags will be added and appear after 15 minutes",
      "customer.table.header.contact": "Contact",
      "customer.table.header.joinDate": "Join Date",
      "customer.table.header.lastPurchased": "Last Purchased",
      "customer.table.actions.enable.information":
        "You want to enable this customer ?",
      "customer.table.actions.disable.information":
        "You want to disable this customer ?",
      "customer.details.header.joined": "Joined",
      "customer.details.header.lastPurchase": "Last Purchase",
      "customer.table.header.joinDateLastPurchase": "Join Date / Last Purchase",
      "customer.enterCustomerEmail": "Enter Customer's Email",
      "customer.searchByEmail": "Search By Email",
      "customer.enterCustomerPhone": "Enter Customer's Phone",
      "customer.enterClientId": "Enter Client's Id",
      "customer.searchByPhone": "Search By Phone",
      "customer.details.pages.basicinfo": "Basic Info",
      "customer.details.pages.phoneemail": "Phone / Email",
      "customer.details.purchase.emptyText":
        "Currently there are no orders for this customer",
      "customer.details.transactions.emptyText":
        "Currently, there are no transactions for this customer.",
      "customer.joinedOn": "Joined On",
      "customer.updatedAt": "Updated On",
      "customer.details.pages.basicinfo.totalorders": "Total Orders",
      "customer.details.pages.basicinfo.purchasesworth": "Purchase worth",
      "customer.details.pages.phonemail.addPhoneNumber": "Add Number",
      "customer.details.pages.phonemail.mailId": "Email Id",
      "customer.details.pages.phonemail.addEmailId": "Add Email ID",
      "customer.details.address.deleted":
        "Address has been successfully deleted",
      "customer.details.phone.deleted":
        "Phone number has been successfully deleted",
      "customer.details.email.send":
        "An email will be sent to the customer shortly.",
      "customer.details.call.call":
        "A call will be made to the customer shortly",
      "customer.details.sms.send":
        "An SMS will shortly be sent to the customer.",
      "customer.details.email.added": "Email added successfully",
      "customer.details.sendMessage": "Send Message",
      "customer.details.sendEmail": "Send email",
      "customer.details.call": "Call",
      "customer.details.address.added": "Address added successfully",
      "customer.details.phone.added": "Phone number added successfully",
      "customer.details.email.deleted":
        "Email id has been successfully deleted",
      "customer.details.order.purchase.history": "Purchase History",
      "customer.details.phone.phoneNumber": "Phone Number",
      "customer.details.call.option.lateDelivery": "Late Delivery",
      "customer.details.call.option.orderEdit": "Order Edit",
      "customer.details.call.option.paymentReminder": "Payment Reminder",
      "customer.details.call.option.rescheduling": "Rescheduling",
      "customer.details.call.option.orderConfirmation": "Order Confirmation",
      "customer.details.call.option.missedCallback": "Missed Callback",
      "customer.details.call.option.addressClarification":
        "Address Clarification",
      "customer.details.call.option.callToInternalEmployees":
        "Call To Internal Employees",
      "customer.details.call.option.tripEnquiry": "Trip Enquiry",
      "customer.details.call.option.surveyOnceShopped": "Survey - Once Shopped",
      "customer.details.call.option.retention": "Rentention",
      "customer.details.call.option.other": "Other",
      "customer.details.mail.submitText.send": "Send",
      "customer.details.sms.message": "Message",
      "customer.address.delete.prompt.title": "Remove this address?",
      "customer.email.delete.prompt.title": "Remove this email ?",
      "customer.phone.delete.prompt.title": "Remove this number ?",
      "customer.confirm.message": "Confirm",
      "customer.cancel.message": "Cancel",
      "customer.phone.delete.prompt.message":
        "This action will remove this number",
      "customer.email.delete.prompt.message":
        "This action will remove this email ID",
      "customer.address.saved": "Address updated",
      "customer.save.success.title": "Password successfully changed!",
      "customer.reset.password.title": "Are you sure ?",
      "customer.reset.password.message":
        "You want to reset customer's password ?",
      "customer.reset.password.cancelText": "No, Cancel",
      "customer.reset.password.confirmText": "Yes, Do it!",
      "customer.details.page.address.notServiceable.error":
        "Address is not serviceable",

      "customer.emailWidget.form.title": "Send email to",
      "customer.smsWidget.form.title": "Send sms to",
      "customer.callWidget.form.reason.placeholder": "Please select an option",
      "customer.callWidget.form.reason.missedCallback": "Missed call back",
      "customer.callWidget.form.reason.addressClarifiation":
        "Address clarifiation",
      "customer.callWidget.form.reason.tripEnquiry": "Trip Enquiry Call",
      "customer.callWidget.form.reason.retention": "Retention",
      "customer.callWidget.form.otherReason.heading": "Other reason",
      "customer.entityMetaData.successInformation":
        "Your settings have been successfully saved",
      "customer.contact.details.default": "Default",
      "customer.contact.details.set.default": "Set as default",

      "customer.details.pages.wallet": "Wallet",
      "customer.details.page.walletCashback": "Wallet Cashback",
      "customer.details.page.walletMoney": "Wallet Money",
      "customer.details.page.creditHeading": "Credit to customer",
      "customer.details.page.encashHeading": "Encash to customer",
      "customer.details.page.creditSuccessTitle": "Credited successfully",
      "customer.details.page.creditSuccessInfo":
        "Amount credited successfully to the wallet",
      "customer.details.page.creditFailureTitle": "Credit failed",
      "customer.details.page.creditFailureInfo":
        "Some error occurred while crediting the amount",
      "customer.details.page.creditSubmittingText": "Crediting amount",
      "customer.details.page.amount": "Amount",
      "customer.details.page.max.cashback": "Cashback to enter Max(500)",

      "operations.dashboard.heading": "Operations dashboard",
      "operations.dashboard.empty.message":
        "Currently there are no orders to show here.",
      "operations.dashboard.sales": "Sales",
      "operations.dashboard.customerAcquired": "Customers acquired",
      "operations.dashboard.customersTransacted": "Customers transacted",
      "operations.dashboard.yesterday": "Yesterday",
      "operations.dashboard.weeklyAvg": "Weekly avg.",
      "operations.dashboard.lastWeek": "Same day last week",
      "operations.dashboard.topProducts.heading": "Top Products",
      "operations.dashboard.count":
        "{count, plural, one{1 time} other{# times}}",
      "operations.dashboard.orderSlot.heading": "Orders Today",
      "operations.dashboard.slot": "Slot",
      "operations.dashboard.counts": "Count",
      "operations.dashboard.total": "Total",
      "order.log.editedBy": "Updated",
      "order.log.customerNA": "Customer not available",
      "order.log.leftAtDoorStep": "Order was left at doorstep",
      "operations.store.add.text": "Add store",
      "operations.store.table.header.deliveyHub": "Has Delivey Hub",
      "operations.store.table.header.picking": "Has Picking",
      "operations.store.form.add.heading": "Add a store",
      "operations.store.form.edit.heading": "Edit store",
      "operations.store.form.name.placeholder": "Enter your store's name",
      "operations.store.form.location.placeholder": "Search for your location",
      "operations.store.form.location.requiredMessage":
        "Please set store's location",
      "operations.store.form.address.placeholder":
        "Enter your store's complete address",
      "operations.store.empty.message":
        "There are currently no stores configured",
      "operations.store.form.hasDeliveryHub": "Has Delivery Hub",
      "operations.store.form.businessHours.title": "Business Hours",
      "operations.store.form.setup.businessHours.title": "Set Business Hours",
      "operations.store.form.businessHours.day": "Day",
      "operations.store.form.businessHours.alwaysOpen": "Always Open",
      "operations.store.form.businessHours.openingTime": "Opening Time",
      "operations.store.form.businessHours.closingTime": "Closing Time",
      "operations.store.form.hasPicking.label": "is picking done here?",
      "operations.store.form.hasDeliveryHub.label": "is this a delivery hub?",
      "operations.store.form.hasClickCollect.label":
        "is this a collection center?",
      "operations.store.form.hasSelfCheckout.label": "self checkout",

      "operations.pickingQueue.itemCount":
        "{count, plural, one{1 Item} other{# Items}}",
      "operations.pickingQueue.emptyList.message":
        "There are currently no orders for picking. Ask your picking and packing staff to download the Zopping Picker app.",
      "default.store": "Default Store",
      "make.primary.store": "Make it Primary Store",
      "use.current.location": "Use current location",

      "shifts.edit.heading": "Edit employee",
      "shifts.empty.message": "There are currently no users",
      "shifts.empty.submessage": "Users can be seen once added",
      "shifts.select.designation": "Select designation",
      "shifts.form.startTime": "Shift Start time",
      "shifts.form.endTime": "Shift end time",
      "shifts.checkout": "Check Out",
      "shifts.checkin": "Check In",
      "shifts.designation.placeholder": "Enter designation",
      "employee.checkedInAt": "Checked in at",

      "dispute.emptyMessage": "Currently there are no disputes",
      "dispute.view.heading": "Disputes for Order",
      "dispute.heading": "Disputed Orders",
      "dispute.referenceNumber": "Reference Number",
      "dispute.orderItem": "Order Item",
      "dispute.filingTime": "Filing Time",
      "dispute.approveReject": "Approve/ Reject",
      "dispute.choose": "Choose",
      "dispute.approve": "Approve",
      "dispute.reject": "Reject",
      "dispute.pickup and refund": "Pickup and refund",
      "dispute.only deliver": "Only Deliver",
      "dispute.pickup and replace": "Pickup and replace",
      "dispute.only refund": "Only refund",
      "dispute.approved": "Approved",
      "dispute.rejected": "Rejected",
      "dispute.name": "Product name",
      "dispute.resolution": "Resolution",
      "dispute.reason.placeholder": "Select Reason",
      "dispute.resolution.placeholder": "Select Resolution",
      "order.dispute.heading": "Dispute for order",
      "order.package.configuration": "Select package",

      "return.heading": "Returns",
      "returns.emptyMessage": "There are no returns",
      "return.view.heading": "Returns for order",
      "return.wastagereason": "Wastage reason",
      "return.toStore": "Store return",
      "return.markasWastage": "Wastage",
      "return.wastagereason.placeholder": "Select wastage reason",
      "return.retunquantity": "Return Quantity",
      "return.totalquantity": "Total Quantity",
      "return.settledQuantity": "Settled Quantity",
      "return.file.heading": "Return order for",
      "return.filter.referenceNumber.placeholder":
        "Enter order reference number",
      "return.move.order": "Move order to",
      "return.move.placeholder": "Select the status",
      "return.heading.for": "Returns for",
      "return.reason.header": "Return reason",
      "return.reasons": "Select the reason",
      "return.no.items.to.return": "No items to return for this order",
      "picker.offDays": "Off days",
      "picker.status": "Current Status",
      "picker.editHeading": "Edit Picker",
      "pickers.emptyMessage": "Currently there are no pickers",

      "pickers.today.title": "Pickers Today",
      "pickers.today.slot": "Shift : ",
      "pickers.today.order.slot": "Slot: ",
      "pickers.today.slot.starttime": "Start: ",
      "pickers.today.slot.estimate.time": "Est: ",
      "pickers.today.slot.current.time": "Now: ",
      "pickers.today.picking.item": "Picking ",
      "pickers.today.packing.item": "Packing ",
      "pickers.today.checking.item": "Checking ",
      "pickers.today.packed.item": "Packed ",
      "pickers.today.picking.item2": " items",
      "pickers.idle.header": "Idle Pickers",
      "pickers.active.header": "Active Pickers",
      "pickers.empty": "Currently there are no pickers available",
      "pickers.today.total.pickers": "Total Pickers",
      "pickers.today.active.pickers": "Active",
      "pickers.today.idle.pickers": "Idle",
      "pickers.today.checkin.time": "Checkin Time: ",
      "pickers.today.late": "Late",
      "picker.today.order": "Order ",
      asapDurationStartText: "Within",
      asapDurationEndText: "minutes",
      "wallet.cashback.expiry": "Expiry",

      "cp.edit": "Edit Rule",
      "cp.add": "Add Rule",
      "cp.area": "Area",
      "zones.placeholder": "Select zone",
      "cutoff.time": "Cutoff time",
      "cutoff.time.placeholder": "Select cutoff time",
      "capacity.placeholder": "Enter capacity",
      "rule.for": "Rule for",
      "day.placeholder": "Select day",
      "cp.empty": "There are no configurations set",
      "cp.today": "Capacity Planning Today",
      "cp.today.cutofftime": "Cutoff Time 1",
      "Thank you for shopping at ": "Thank you for shopping at {name}",
      "Visit us at": "Visit us at {domain}",
      "orderDetails.uploadPrescription.prescription": "Prescription",
      "orderDetails.uploadPrescription.addPrescription": "Add Prescription",
      "orderDetails.uploadPrescription.uploadImagePlaceholderText":
        "Click here to upload or drag images",
      "orderDetails.uploadPrescription.enterDoctorName": "Enter Doctor's Name",
      "orderDetails.uploadPrescription.enterPatientName":
        "Enter Patient's Name",
      "orderDetails.uploadPrescription.patientName": "Patient's Name",
      "orderDetails.uploadPrescription.doctorName": "Doctor's Name",
      "orderDetails.uploadPrescription.upload": "Upload",
      "orderDetails.uploadPrescription.dr": "Dr.",
      "orderDetails.uploadPrescription.uploadedPrescription":
        "Uploaded Prescription",
      "orderDetails.uploadFile": "Uploaded File",

      "orderPackageConfig.createNew.heading": "Create new package",
      "orderPackageConfig.length.label": "Package length (cm)",
      "orderPackageConfig.length.placeholder": "Enter package length in cm",
      "orderPackageConfig.breadth.label": "Package breadth (cm)",
      "orderPackageConfig.breadth.placeholder": "Enter package breadth in cm",
      "orderPackageConfig.height.label": "Package height (cm)",
      "orderPackageConfig.height.placeholder": "Enter package height in cm",
      "orderPackageConfig.type.label": "Package type",
      "orderPackageConfig.type.placeholder": "Enter the type of package",
      "orderPackageConfig.package.length": "Length",
      "orderPackageConfig.package.breadth": "Breadth",
      "orderPackageConfig.package.height": "Height",
      "orderPackageConfig.createPackage.errorMsg": "Cannot create package",
      "orderPackageConfig.createPackage.errorMsg.dsc":
        "Some error occurred while creating package",
      "orderPackageConfig.package.creating": "Creating package",
      "orderPackageConfig.createPackage.successMsg": "Package Created!",
      "orderPackageConfig.createPackage.successMsg.dsc":
        "Successfully created the package",
      "orderPackageConfig.deletePackage.heading": "Delete Package?",
      "orderPackageConfig.deletePackage.subHeading":
        "Are you sure, you want to delete the selected package?",
      "orderPackageConfig.editPackage.errorMsg.dsc":
        "Some error occurred while editing the selected package",
      "orderPackageConfig.editPackage.errorMsg": "Cannot edit package",
      "orderPackageConfig.editPackage.successMsg": "Package Edited!",
      "orderPackageConfig.editPackage.successMsg.dsc":
        "Successfully edited the package",
      "orderPackageConfig.editPackage.heading": "Edit package",
      "orderPackageConfig.package.editing": "Editing package",
      "orderPackageConfig.package.emptyMsg":
        "Create a new package to show here",

      "resetPassword.heading": "Forgot password?",
      "resetPassword.successMessage":
        "Your password has been successfully reset",
      "resetPassword.loginLinkText": "Go to login",
      "resetPassword.oldUserText": "Already have an account?",

      "onboarding.basicInfo.heading": "Provide Store Information",
      "onboarding.basicInfo.subDomain.text": "subdomain",
      "onboarding.basicInfo.register.text": "register",
      "onboarding.basicInfo.newOne.text": "a new one",
      "onboarding.industry.sencondaryHeading": "Select an industry below",
      "onboarding.products.heading": "Add products to your website",
      "onboarding.products.sencondaryHeading":
        "Use below 10 sample products to create your Grocery website now and",
      "onboarding.theme.heading": "Choose your theme",
      "onboarding.theme.sencondaryHeading":
        "Here is your default theme for your Grocery website and",
      "onboarding.createWebsite.text": "Create your website now",
      "onboarding.changeLater.text": "you can change later here",

      "analytics.tracking.heading": "GOOGLE ANALYTICS TRACKING ID",
      "analytics.tracking.placeholder":
        "Enter your google analytics tracking id",
      "analytics.conversionPixel.heading": "CONVERSION PIXEL",
      "analytics.tracking.description":
        "You can find your tracking ID by going to Google Analytics Admin > Property > Tracking Info > Tracking Code. Tracking ID will be of form UA-XXXXXXXX-X.",
      "analytics.conversionPixel.description":
        "Google analytics conversion will be automatically tracked, if tracking ID has been provided. Any additional conversion pixel code can be added here. The codes will be fired when a new order comes.",
      "save.text": "Save",
      "cancel.text": "Cancel",
      "analytics.invalidTrackingId": "Enter a valid Tracking ID",

      "basicInfo.storename.heading": "Store Name",
      "basicInfo.storename.support.phone": "Support Phone",
      "basicInfo.storename.support.phone.placeholder":
        "Enter a support phone number",
      "basicInfo.form.minimumOrderValue.placeholder":
        "Enter the minimum order value",
      "basicInfo.storename.support.email": "Support Email",
      "basicInfo.storename.support.email.placeholder":
        "Enter a support email address",
      "basicInfo.storename.description.onboarding":
        "Please enter your online store name",
      "basicInfo.storename.description":
        "Store name is used in customer communications. It can also be used by themes to display your name in the website and mobile applications.",
      "basicInfo.storename.placeholder": "Your store",
      "basicInfo.storeurl.heading": "Store Url",
      "basicInfo.storeurl.placeholder": "Enter your store name",
      "basicInfo.supportWWW": "SUPPORT WWW",
      "basicInfo.uploadImage.heading": "Upload Your Logo (optional)",
      "basicInfo.uploadImage.placeholder":
        "Click here to upload or drag your logo",
      "basicInfo.uploadImage.description":
        "Logo should be of at least 180x55 px and a maximum of 2MB. This logo will appear on your website, mobile applications and your emails to your customers.",
      "basicInfo.customDomain.description":
        "Create a new CNAME record pointing to {domain}",
      set_dns_settings:
        "Set CNAME record in your DNS settings as stores.zopping.com",
      "basicInfo.invalidDomain": "Enter a valid domain name",
      "basicInfo.invalidDomain.url": "Enter a valid domain URL",
      "basicInfo.save.success.title": "Settings have been successfully saved",
      "support.ticket.comment.succes.title": "Message submitted sucessfully",
      "okay.text": "Okay",
      "basicInfo.save.error.title":
        "Something went wrong while saving. Please try again later",
      "basicInfo.uploadFavicon.heading": "Upload Your Favicon (optional)",
      "basicInfo.uploadFavicon.placeholder":
        "Click here to upload or drag your Favicon",
      "basicInfo.uploadFavicon.description":
        "Upload fav icon in 32x32 px (recommended) with transparent background.",

      "socialMedia.heading": "Social Media",
      "socialMedia.whatsappSupportEnabled": "Enable WhatsApp Support",
      "socialMedia.whatsappSupportPhone": "WhatsApp Support Phone",
      "socialMedia.whatsappSupportPhone.placeholder":
        "Enter WhatsApp Support Phone Number",
      "socialMedia.whatsappSupportEnquiryText": "Whatsapp Support Enquiry Text",
      "socialMedia.whatsappSupportEnquiryText.placeholder":
        "Enter Whatsapp Support Enquiry Text",
      "socialMedia.facebook.heading": "Official Facebook Page",
      "socialMedia.twitter.heading": "Official Twitter Page",
      "socialMedia.google.heading": "Official Google+ Page",
      "store.android.heading": "Official playstore Page",
      "store.ios.heading": "Official ios Page",
      "socialMedia.youtube.heading": "Official Youtube Page",
      "socialMedia.instagram.heading": "Official Instagram Page",
      "socialMedia.facebook.allowlogin": "Allow Facebook Login",
      "socialMedia.twitter.allowlogin": "Allow twitter Login",
      "socialMedia.google.allowlogin": "Allow google Login",
      "socialMedia.instagram.allowlogin": "Allow Instagram Login",
      "socialMedia.facebook.description":
        "If you have a facebook page for your online store, you can provide details here. You can also allow your customers to use their facebook account to login on your Store.",
      "socialMedia.twitter.description":
        "Themes can use the twitter handle to show your details on your online store. Platform can also automatically display tweets related to your handle.",
      "socialMedia.youtube.description":
        "Themes can use the youtube handle to show your details on your online store. Platform can also automatically display videos related to your handle.",
      "socialMedia.instagram.description":
        "Themes can use the instagram handle to show your details on your online store. Platform can also automatically display posts related to your handle.",
      "socialMedia.google.description":
        "Google page link can be displayed on the website and mobile app. You can also allow your customers to login using google account.",
      "store.android.description":
        "This will help you to download app from android playstore, So customer can get direct link for app and visit there",
      "store.ios.description":
        "This will help you to download app from ios store, So customer can get direct link for app and visit there",
      "handle.text": "handle",
      "socialMedia.google.placeholder": "account ID",
      "media.select.facebook": "Facebook",
      "media.select.twitter": "Twitter",
      "media.select.iosAppLink": "ios",
      "media.select.androidAppLink": "android",
      "media.select.youtube": "Youtube",
      "media.select.instagram": "Instagram",
      "socialMedia.accounts.placeholder": "Select social media",
      "store.accounts.placeholder": "Select app store",
      "socialMedia.accounts.label": "Add social media",
      "store.accounts.label": "Add app links",
      "orderConfig.form.minimumOrderValue.heading": "Minimum order value",
      "orderConfig.form.minimumOrderValueFreeDelivery.heading":
        "Minimum order value for free delivery",
      "orderConfig.form.minimumOrderValueFreePickup.heading":
        "Minimum order value for free pickup",
      "orderConfig.form.minimumOrderValueFreeDelivery.placeholder":
        "Enter the minimum order value for free delivery",
      "orderConfig.form.minimumOrderValueFreePickup.placeholder":
        "Enter the minimum order value for free pickup",
      "orderConfig.form.deliveryFees.placeholder": "Enter the delivery fees",
      "orderConfig.form.pickupFees.placeholder": "Enter the pickup fees",

      "productConfig.heading": "Product Config",
      "product.config.checkoutAllowed":
        "Display Add to cart button on the website",
      "product.config.showPriceToggle": "Display price on the website",

      "themes.heading": "Themes",
      "theme.title": "Theme",
      "themes.subheading": "You are using {name} theme",
      "themes.customize.subheading": "You are customizing {name} theme",
      "themes.add.text": "Click here to add a layout",
      "themes.change.text": "Change",
      "themes.customize.text": "Customize",
      "themes.change.heading": "Change Theme",
      "themes.selectTheme.text": "Use this theme",
      "themes.selection.heading": "Choose a layout",
      "themes.layout.save.success": "Layouts saved!",
      "themes.error.Validation Exception: Missing required layouts - ProductCollection":
        "This layout cannot be deleted",
      "themes.error.Parameter text/url is required for this request":
        "Missing Text/URL ",
      "themes.error.Parameter layouts is required for this request":
        "Please add data",
      "themes.layout.save.title.error": "Oops",
      "ok.text": "Ok",
      "themes.images": "Images",
      "themes.category.show": "Categories to show",
      "themes.brand.show": "Brands to show",
      "themes.tag.show": "Tags to show",
      "themes.searchable.label": "Override layout for",
      "themes.category.show.layout": "Categories",
      "themes.product.show.layout": "Products",
      "themes.brand.show.layout": "Brands",
      "themes.tag.show.layout": "Tags",
      "themes.tag.filter.label": "Filter Should Appear",
      "themes.product": "products should have",
      "themes.sortinglogic": "Select sorting logic",
      "themes.cc.name": "Collection name",
      "themes.cc.name.placeholder": "Enter collection name",
      "themes.bc.name": "Collection name",
      "themes.bc.name.placeholder": "Enter collection name",
      "themes.cc.categories.placeholder": "Enter categories to show",
      "themes.bc.blog.placeholder": "Enter blogs to show",
      "themes.cc.showSubCategory.inline": "Show subcategory links",
      "themes.cc.config": "Configs",
      "themes.cc.pdtCount.inline": "Show product count",
      "themes.bc.categories.placeholder": "Enter brands to show",
      "themes.layoutType": "Layout Type",
      "themes.grid": "Grid",
      "themes.scroller": "Scroller",
      "themes.cc.subCategories": "Show subcategories collection",
      "themes.customize.nav": "Navigation",
      "themes.cc.config.image": "Show as cover image",
      "themes.layout.timeline.heading": "Layout Timeline",
      "themes.layout.timeline.startTime.placeholder": "Select start time",
      "themes.layout.timeline.endTime.placeholder": "Select end time",
      "themes.loadMoreType": "Load more products using",
      "themes.loadMoreImageType": "Load more images using",
      "themes.infiniteScroll": "Infinite scroller",
      "themes.seeAll": "See all button",

      "imageSlideShow.addSlide": "Add Slide",
      "imageCollection.addImage": "Add Image",
      "footer.addSection": "Add Section",
      "footer.addItem": "Add Item",
      "marquee.addSlide": "Add Slide",
      "hotSpot.addSlide": "Add Hot Spot",
      "accordionLayout.addImage": "Add Question",

      "dualImageBanner.linkText": "Link Text",
      "dualImageBanner.image1": "image 1",
      "dualImageBanner.link": "link",
      "dualImageBanner.image2": "image 2",
      "dualImageBanner.subTitle": "sub title",
      "dualImageBanner.InputLinkPlaceholder": "Please enter link here",
      "dualImageBanner.InputSubtitlePlaceholder": "Please enter sub title here",
      "dualImageGrid.enterTitle": "Please enter title here",
      "dualImageGrid.enterSubtitle": "Please enter subtitle here",

      "videoCarousel.videoUrl.title": "video URL",
      "videoCarousel.videoUrl.placeholder": "Enter embed Video URL",
      "videoCarousel.description.placeholder": "Enter the Description here",
      "videoCarousel.textColor.label": "text color",
      "videoCarousel.textColor.placeholder": "text color for description",
      "videoCarousel.fontSize.label": "font size (in px)",
      "videoCarousel.fontSize.placeholder": "font size for description in px",
      "videoCarousel.button.text": "Button Text",
      "videoCarousel.secondary.button.text": "Secondary button Text",
      "videoCarousel.button.placeholder": "Enter button name",
      "videoCarousel.button.link": "Button Link",
      "videoCarousel.secondary.button.link": "Secondary button Link",
      "videoCarousel.button.link.placeholder": "Enter button redirection url",

      "extensions.icon": "Icon",
      "extensions.addedOn": "Added On",
      "extensions.monthlyBillingEstimate": "Monthly Billing Estimate",
      "extensions.install": "Install",
      "extensions.uninstall": "Uninstall",
      "extensions.installExtension": "Install Extension",
      "extensions.uninstallExtension": "Uninstall Extension",
      "extensions.monthlyEstimate": "Monthly Estimate",
      "extensions.version": "Version",
      "extensions.lastUpdated": "Last Updated",
      "extensions.activeInstallations": "Active Installations",
      "extensions.policy": "I have read and agreed with the privacy policy",
      "extensions.installedSuccessfully": "Installed Successfully",
      "extensions.uninstalledSuccessfully": "Uninstalled Successfully",
      "extensions.enterExtensionName": "Enter Extension Name",
      "submit.text": "Submit",
      "extensions.refreshPrompt.title":
        "It is recommended that you logout and login again for changes to take full effect",
      "extension.details.notfound": "There are no settings for this extension",
      "extensions.configure": "Configure",
      "extensions.manage": "Manage",
      "extensions.whatwillchange": "What will change?",

      "extensions.multiLingual.heading": "Multilingual Support",
      "extensions.multiLingual.placeholder": "Select Language(s)",

      "extensions.analytics.heading": "Google Analytics Configuration",
      "extensions.fbAnalytics.heading": "Facebook Analytics Configuration",
      "extensions.courier.support.heading": "Courier Support",
      "extensions.analytics.gaAccount.heading": "GA Account",
      "extensions.analytics.fbPixel.heading": "Facebook Pixel Id",
      "extensions.analytics.fbPixel.placeholder": "Enter Facebook Pixel Id",
      "extensions.analytics.fbEvents.heading": "Facebook Events Manager Id",
      "extensions.analytics.fbEvents.placeholder":
        "Enter Facebook Events Manager Id",
      "extensions.shiprocket.username.placeholder": "Enter Username",
      "extensions.shiprocket.password.placeholder": "Enter Password",
      "extensions.analytics.gaAccount.description":
        "The Google Analytics Account registered to the customer",
      "extensions.analytics.fbPixel.description":
        "Go to your Facebook Event Manager to find your Facebook Pixel ID",
      "extensions.analytics.fbEvents.description":
        "The Facebook Events Manager Account registered to the customer",
      "extensions.analytics.conversionTag.heading": "Conversion Tag",
      "extensions.analytics.conversionTag.placeholder":
        "Paste code for conversion pixel here",
      "extensions.analytics.conversionTag.description":
        "JS Snippets to track conversions of users transacted on customer's website",

      "extensions.deliveryArea.deliveryFee": "Delivery fee",
      "extensions.deliveryArea.zones.heading": "Delivering Zones",
      "extensions.deliveryArea.pincodes.heading": "Zipcodes",
      "extensions.deliveryArea.zone.unnamed": "Unnamed Group",
      "extensions.deliveryArea.polygon.title": "Draw On Map",
      "extensions.deliveryArea.polygon.unnamed": "Unnamed Area",
      "extensions.deliveryArea.radial.title": "Distance From Store",
      "extensions.deliveryArea.radial.location.error":
        "Select a location on the map",
      "extensions.deliveryArea.apartment.heading": "Delivery by Apartment",
      "extensions.deliveryArea.delete.warning":
        "Would you like to delete this group?",

      "extentions.deliveryArea.fee.formula.heading":
        "Configure Delivery Fee Formula:",
      "extentions.deliveryArea.fee.formula.left.text": "Delivery Fee =",
      "extentions.deliveryArea.fee.formula.right.text":
        "[Additional fixed cost] + ([Order total weight] - [Lower weight limit]) * [Rate per weight unit] + [Order subtotal] * [Charge percentage]",

      "extensions.tawk.site.id": "Site Id",
      "extensions.tawk.site.id.placeholder": "Enter your Site Id",
      "extensions.tawk.site.id.description":
        "Your site ID can be found in the tawk website in ",
      "extensions.tawk.widget.id": "Widget Id",
      "extensions.tawk.widget.id.placeholder": "Enter your Widget Id",
      "extensions.tawk.widget.id.description":
        "Your widget ID can be found in the tawk website in ",

      "seo.heading": "Custom SEO Content",
      "extensions.seo.title.explanation":
        "Choose a title that reads naturally and effectively communicates the topic of the page's content.",
      "extensions.seo.description.explanation":
        "Write a description that would both inform and interest users if they saw your description in a search result. It is recommended to make it long enough to be fully shown in Search, and it would contain the relevant information users would need to determine if the page is relevant to them.",
      "extensions.seo.keywords": "Keywords",
      "extensions.seo.keywords.explanation":
        "Think about the words that a user might search for to find a piece of your content. Users who know a lot about the topic might use different keywords in their search queries than someone who is new to the topic.",
      "extensions.seo.read.more": "Learn more",

      "extensions.slot.heading": "Slots configuration",
      "extensions.slot.numOfDays": "HOW MANY DAYS TO SHOW TO CLIENT ?",
      "error.text": "Error",
      "extensions.slot.businessTime.heading":
        "Business timing for ASAP slot availability",
      "extensions.slot.businessOpeningTime": "Opens at",
      "extensions.slot.businessClosingTime": "Closes at",

      "extensions.password-policy.heading": "Password Policy",
      "extensions.password-policy.label.minNumberOfLowercaseAlphabets":
        "minimum number of lower case alphabets",
      "extensions.password-policy.placeholder.minNumberOfLowercaseAlphabets":
        "Enter minimum number of lower case alphabets",

      "extensions.password-policy.label.minNumberOfNumericCharacters":
        "minimum Number Of Numeric Characters",
      "extensions.password-policy.placeholder.minNumberOfNumericCharacters":
        "Enter minimum number of numeric characters",

      "extensions.password-policy.label.minNumberOfSymbols":
        "minimum Number Of Symbols",
      "extensions.password-policy.placeholder.minNumberOfSymbols":
        "Enter minimum number of symbols",

      "extensions.password-policy.label.minNumberOfUppercaseAlphabets":
        "minimum number of upper case alphabets",
      "extensions.password-policy.placeholder.minNumberOfUppercaseAlphabets":
        "Enter minimum number of upper case alphabets",

      "extensions.password-policy.label.minPasswordLength":
        "minimum password length",
      "extensions.password-policy.placeholder.minPasswordLength":
        "Enter minimum password length",

      "extensions.ondc.integration.title":
        "ONDC Integration Basic Configuration",
      "extensions.ondc.integration.input.fssaiNo": "FSSAI No",
      "extensions.ondc.integration.input.upiAddress": "UPI Address",
      "extensions.ondc.integration.input.businessName": "Business Name",
      "extensions.ondc.integration.input.businessName.desc":
        "Legal Entity Name/ Business Name (as per GSTN)",

      "extensions.ondc.integration.input.businessAddress": "Business Address",
      "extensions.ondc.integration.input.businessAddress.desc":
        "Business Address (primary place of business)",

      "extensions.ondc.integration.input.gstDetails": "GST Details",
      "extensions.ondc.integration.input.gstDetails.desc":
        "GST Details (For primary place of business)",

      "extensions.ondc.integration.input.panNo": "PAN No",
      "extensions.ondc.integration.input.nameOfAuthorisedSignatory":
        "Name of the Authorised Signatory",
      "extensions.ondc.integration.input.nameOfAuthorisedSignatory.desc":
        "Name of Authorised Signatory for digitally signing transactions",
      "extensions.ondc.integration.input.addressOfAuthorisedSignatory":
        "Address of the Authorised Signatory",
      "extensions.ondc.integration.input.emailID": "Email ID",
      "extensions.ondc.integration.input.mobileNo": "Mobile No",
      "extensions.ondc.integration.input.rtoCharges": "RTO charges",
      "extensions.ondc.integration.input.rtoCharges.desc":
        "Return to origin after dispatch charges",

      "extensions.ondc.integration.input.includeBAPFee": "Include BAP Fee",
      "extensions.ondc.integration.input.includeBAPFee.desc":
        "Raise the declared price by buyer finder fee",

      "extensions.order.returns.title": "Order Returns",
      "extensions.order.returns.input.disputeDays": "Dispute Days",
      "extensions.order.returns.allowReplacementOfDisputeItems":
        "Allow replacement of dispute items",

      "extension.prerequisites": "Prerequisites",
      "to.install.extension": "To install this extension",
      "to.use.this.extension": "To use this extension",
      "google.map.api.key.help.text":
        "Google Maps API key is necessary to know your customer location. This in turn is necessary to allow/ restrict users from placing orders beyond the areas defined via this extensions.",
      "configure.google.maps.api": "Configure google maps API",
      "learn.to.get.gmak": "Learn how to get Google Maps API Key",

      "mobileApps.name.heading": "App Name",
      "mobileApps.package.name": "Package Name",
      "mobileApps.name.placeholder": "Enter your app's name",
      "mobileApps.package.placeholder": "Enter your app's package name",
      "mobileApps.shortDescription.suggestion":
        "App icon should be of atleast 1024x1024px and maximum of 5MB",
      "mobileApps.longDescription.suggestion":
        "App Splash screen should be of atleast 1280x1920px and maximum of 5MB",
      "mobileApps.googleServices.title": "upload google services json file",
      "mobileApps.googleFirebase.title": "upload google firebase json",
      json_uploaded_success: "JSON file uploaded successfully!",
      "mobileApps.publish.title":
        "Publish your app under zopping App store/ playstore account.",
      "mobileApps.requestReceived.title":
        "We have received your request. Our team will reach out to you on future steps.",
      "mobileApps.appIcon.heading": "Upload your app icon",
      "mobileApps.squareIcon.heading": "Upload your square app icon",
      "mobileApps.circularIcon.heading": "Upload your circular app icon",
      "mobileApps.appIcon.uploadText":
        "Click here to upload or drag your App icon",
      "mobileApps.appIcon.placeholder":
        "2048 x 2048 px png transparent background",
      "mobileApps.appIcon.tooltip":
        "This image represents the app's icon on mobile devices.",
      "mobileApps.splashScreen.heading": "upload splashscreen",
      "mobileApps.splashScreen.tooltip":
        "Apps often take some time to to start up, especially when the app is first launched on a device. The splash screen is shown meanwhile, to display start up progress to the user or to indicate branding.",
      "mobileApps.theme.loadingText": "Loading themes",
      "mobileApps.download.apk": "Download APK",
      "mobileApps.submit.request": "Submit Request",
      "mobileApps.success.request": "Request success",
      "mobileApps.failed.request": "Request failed",

      "settings.users.invite.text": "Invite User",
      "settings.users.action.revoke": "Revoke Invitation",
      "settings.users.action.editPermissions": "Edit Permissions",
      "settings.users.inviteForm.name.placeholder": "Enter user's name",
      "settings.users.inviteForm.email.placeholder": "Enter user's email",
      "settings.users.inviteForm.phone.placeholder":
        "Enter user's phone number",
      "settings.users.inviteForm.invite.buttonText": "Invite",
      "settings.users.permissionsForm.headingWithoutName": "Permissions",
      "settings.users.permissionsForm.heading": "Permissions for {name}",
      "settings.users.permissionsForm.header.read": "Read",
      "settings.users.permissionsForm.header.write": "Write",
      "settings.users.permissionsForm.copyPlaceholder.name": "No User",
      "settings.users.permissionsForm.copyPlaceholder.text":
        "Select a user to copy permissions",

      "deliveryArea.emptyList.message": "There are no delivery areas",
      "deliveryArea.disableDialog.information":
        "You want to disable this delivery area",
      "deliveryArea.enableDialog.information":
        "You want to enable this delivery area",
      "disabled.text": "Disabled",
      "deliveryArea.filters.configType.label.radial": "RADIAL",
      "deliveryArea.filters.configType.label.pincode": "ZIPCODE",
      "deliveryArea.filters.configType.label.polygon": "POLYGON",
      "deliveryArea.editSlot.heading": "Delivery area slot rules",
      "deliveryArea.slotConfigure": "Configure slots",

      "deliveryCharge.heading.text": "Delivery Charge",
      "served.from.store.text": "Served From Store",
      "radial.form.distance.description":
        "Define distance radius to cover for delivery",
      "radial.form.map.description": "Drag and click to mark location",
      "radial.form.searchLocation.placeholder": "Search location on map",
      "radial.table.description": "from the store location",
      "radial.selectLocation.message": "Select a Location",
      "radial.form.edit.heading": "Edit distance from store",

      "rackmanagement.heading": "Rack Management",
      "rackManagement.rack.add.placeholder":
        "Enter picking sequence (comma separated)",
      "rackManagement.add.more.racks": "Add Sequence",
      "rackManagement.clear.sequence": "Clear Sequence",
      "rackManagement.popup.heading": "Add Picking Sequence",
      "rackManagement.save.success.title":
        "Setting have been successfully saved",
      "rackManagement.rack.delete.message": "You want to delete this rack",
      "rackManagement.rack.delete.confirmText": "Yes, delete it!",
      "rackmanagement.empty.message": "Racks are not configured",
      "rackManagement.rack.add.zone": "Add new zone",
      "rackManagement.rack.add.zone.placeholder": "Enter zone",
      "rackManagement.rack.add.zone.default": "Default zone",
      "rackManagement.rack.zones": "Zones",
      "rackManagement.rack.zones.type.existing": "Add to existing zone",
      "rackManagement.rack.zones.type.new": "Add a new zone",
      "rackManagement.rack.zone.name": "Zone name",

      "pincode.form.pincodes.heading": "ZIP Codes",
      "pincode.form.pincodes.placeholder": "Type ZIP Codes and Press Enter",
      "pincode.form.edit.heading": "Edit Zip Code / Postal Code",
      "polygon.form.map.description": "Click on area to delete",

      "notifications.add.email": "Add Email",
      "notifications.add.phone": "Add phone number",
      "notifications.new.order": "When new order comes",
      "notifications.order.title": "Order Notifications",
      "notifications.order.cancel": "When order gets cancelled",
      "notifications.order.refund": "When order gets refunded",
      "notifications.customer.title": "Customer Notifications",
      "notifications.customer.add.money": "When customer adds money to wallet",
      "notifications.customer.add.address": "When customer adds new address",
      "notifications.customer.complain": "When customer registers complain",
      "notifications.email.mail": "Add email address",
      "label.enter.phone.number": "Enter your phone number",
      "notification.save.success.phone": "Phone added succesfully",
      "notification.email.delete.title": "Are yor sure?",
      "notification.email.delete.message": "This email will be deleted",
      "notification.phone.delete.message": "This phone number will be deleted",
      "notification.email.error": "Email already exists",
      "notification.phone.error": "Mobile number already exists",
      "notification.success": "Success",
      "notification.success.save": "Changes successfully saved",
      "notification.okay.text": "Okay",
      "notification.new.customer": "When new customer joins",

      "polygon.table.description": "Marked location",
      "polygon.table.subdescription": "area in the Map",
      "polygon.delete.text": "Click to delete",
      "polygon.selectLocation.message": "Draw area on map",

      "orderprocessing.config.heading": "Order Processing Settings",
      "orderprocessing.config.allStores": "Default Settings",
      "orderprocessing.config.form.resetToDefault": "Reset To Default",
      "orderprocessing.qrCodeFormat.heading":
        "Store Printed Local Barcode Format",
      "orderprocessing.qrCodeFormat.description":
        "Use `w` to indicate weight or `p` to indicate price. Only one of them will be used. Use `i` to indicate unique identifier.",
      "orderprocessing.config.pickingMethod.placeholder":
        "Select picking sequence method",
      "orderprocessing.config.pickingMethod.heading": "Picking Sequence Method",
      "orderprocessing.pickingMethod.oldestfirst":
        "Show oldest order in picking queue first",
      "orderprocessing.pickingMethod.farthestfirst":
        "Show order farthest from the store in picking queue first",
      "orderprocessing.config.pickingView.heading":
        "How Picker Sees the Order Items?",
      "orderprocessing.pickingView.allitems": "All at once",
      "orderprocessing.orderLabelPrinting.heading": "Order label printing",
      "orderprocessing.orderLabelPrinting.startofpicking":
        "At start of picking",
      "orderprocessing.orderLabelPrinting.endofpicking": "At end of picking ",
      "orderprocessing.orderLabelPrinting.startandendofpicking":
        "At start and end of picking",
      "orderprocessing.orderLabelPrinting.notneeded":
        "No physical print needed",
      "orderprocessing.orderLabelFormat.heading": "Order label format",
      "orderprocessing.orderLabelFormat.onlyordernumber": "Only order number",
      "orderprocessing.orderLabelFormat.ordernumberandcustomerdetails":
        "Order number with customer details in QR code",
      "orderprocessing.orderLabelFormat.ordernumbercustomeranditemdetails":
        "Order number with customer details and item in QR code",
      "orderprocessing.qualityChecking.heading": "Quality checking",
      "orderprocessing.packingDetails.donebypicker": "Done by picker",
      "orderprocessing.packingDetails.donebypacker": "Done by packer",
      "orderprocessing.pickingMethod.intelligentpickingqueue":
        "Intelligent picking queue",
      "orderprocessing.pickingView.onebyone": "One by one",
      "orderprocessing.orderLabelPrinting.endofpacking": "At end of packing",
      "orderprocessing.qualityChecking.notneeded": "Not needed",
      "orderprocessing.qualityChecking.donebychecker": "Done by checker",

      "settings.payments.heading":
        "Choose the payment modes to accept money from your customers",
      "settings.payments.method.cod": "Cash on Delivery (COD)",
      "settings.payments.method.cod.description":
        "Customers can pay for their orders through cash at the time of delivery or pickup",
      "settings.payments.method.online.payment": "Online Payment",
      "settings.payments.method.wallet": "Wallet",
      "settings.online.payment.succesfully.saved":
        "Settings successfully saved",
      "settings.online.payment.gateway.saved":
        "Payment gateway successfully saved",
      "delete.text": "Delete",
      "settings.online.payment.account.delete": "The account will be deleted",
      "settings.online.payment.bank.account.details": "Bank account details",
      "settings.online.payment.link.bank.account": "Link a bank account",
      "settings.online.payment.our.gateway":
        "You are using our Payment Gateway",
      "settings.online.payment.gateway": "Payment Gateway",
      "settings.online.payment.bank.name": "Bank name",
      "settings.online.payment.accountnumber": "Bank account number",
      "settings.online.payment.beneficiaryname": "Beneficiary name",
      "settings.online.payment.accountholdername": "Account Holder Name",
      "settings.online.payment.branchifsccode": "Branch IFSC Code",
      "settings.online.payment.ifsccodeofbankbranch":
        "IFSC Code of the Bank Branch",
      "settings.online.payment.state": "State",
      "settings.online.payment.enterstate": "Enter State",
      "settings.online.payment.removebankaccount": "Remove bank account",
      "settings.online.payment.ihaveagateway": "I have a Payment Gateway",
      "settings.online.payment.ihaveagatewaydescription":
        "If you have your own payment gateway and would like to integrate it with your website, then this option is for you.",
      "settings.online.payment.idonthaveagateway":
        "I don't have a Payment Gateway",
      "settings.online.payment.idonthaveagatewaydescription":
        "If you do not have your own payment gateway, then you can choose from a list of third party payment gateways supported by us for payments on your website.",
      "online.payments.link.bank.account": "Link Bank Account",
      "settings.online.payment.linked.successfully":
        "Payment Gateway has been activated",
      "settings.online.payment.linked.bank":
        "Linked the bank to your payment gateway",
      "settings.online.payment.configureexistinggateways":
        "Please configure the existing gateways before adding new gateway",
      "settings.online.payment.use.your.gateway": "Use your gateway",
      "settings.online.payment.use.zoppay": "Use our default gateway",
      "integrationSettings.googleMapsKey.label": "Google Maps Api Key",
      "integrationSettings.googleMapsKey.placeholder":
        "Enter your Google map api key",
      "integrationSettings.googleCaptchaKey.label": "Google Captcha Site Key",
      "integrationSettings.googleCaptchaKey.placeholder":
        "Enter your Google captcha site key",
      "integrationSettings.googleCaptchaKey.description":
        "On your Google reCaptcha Admin Console, navigate to Settings → reCAPTCHA keys",
      "integrationSettings.googleCaptchaSecret.label":
        "Google Captcha Secret Key",
      "integrationSettings.googleCaptchaSecret.placeholder":
        "Enter your Google captcha secret key",
      "integrationSettings.googleCaptchaSecret.description":
        "On your Google reCaptcha Admin Console, navigate to Settings → reCAPTCHA keys",
      "integrationSettings.dialog.success.info":
        "Settings are saved successfully",
      "integrationSettings.gtm.label": "Google Tag Manager Id",
      "integrationSettings.gtm.placeholder": "Enter your Google tag manager Id",
      "integrationSettings.gtm.description": `On your Google Tag Manager account, open a container. Near the top of the window, find your container ID, formatted as "GTM-XXXXXX"`,

      "slots.emptymessage": "There are no slots configured",
      "slots.addHeading": "Add Slot",
      "slot.meridian": "Meridian",
      "slots.startsAt": "Starts at",
      "slots.endsAt": "Ends at",
      "slots.start.end.mismatch": "Start time should be lesser than end time",
      "slots.asap.placeholder": "0 Minute",

      "entityMetaData.number.placeholder": "Enter Number",
      "key.label.text": "Key",
      "entityMetaData.checkboxLabel": "Filter",
      "entityMetaData.checkboxMultivaluedLabel": "multivalued",
      "entityMetaData.text.placeholder": "Enter Key",
      "entityMetaData.this.key.will.be.deleted": "This key will be deleted",
      "entityMetadata.add": "Add key",

      "picking.config.heading": "Picking Configuration",
      "picking.config.multiplePickingAllowed": "Allow multiple picking",
      "picking.config.partialPickingAllowed": "Allow partial picking",
      "picking.config.maxOrdersCanBePickedAtOnce":
        "Maximum number of orders can be picked at once",
      "picking.config.maxOrdersCanBePickedAtOnce.placeholder":
        "Enter the maximum number of orders can be picked at once",

      "wallet.config.heading": "Wallet Configuration",
      "wallet.config.cashbackPercentage": "Cashback Percentage",
      "wallet.config.cashbackPercentage.placeholder":
        "Enter the cashback percentage",
      "wallet.config.daysAfterOrderCompletionForCashback":
        "Days After Order Completion For Cashback",
      "wallet.config.daysAfterOrderCompletionForCashback.placeholder":
        "Enter the days after order completion for cashback",
      "wallet.config.cashbackExpiresAfter": "Expires after",

      "store.min.stock.count": "Min buffer count",
      "store.max.purchaseable.qty": "Max Purchasable Quantity",
      "store.min.purchaseable.qty": "Min Purchasable Quantity",
      "store.configuraion.add.text": "Add configuration",
      "stores.empty.stock": "There are no configurations",
      "store.min.purchaseable.quantity": "Min Qty",
      "store.max.purchaseable.quantity": "Max Qty",
      "enitityMetaData.values": "Values",
      "enitityMetaData.values.placeholder": "Enter values",
      "entityMetaData.country": "Countries",
      "entityMetaData.country.placeholder": "Select countries",
      "search-configuration.header.mrp": "MRP",
      "search-configuration.emptystate.message":
        "Currently there are no configurations",
      "search-config.searchurls": "Add search keywords",
      "search-config.searchurls.placeholder": "Enter search keywords",
      "search-configuration.form.label.select.position.placeholder":
        "Enter position",
      "sc.form.cat.placeholder": "Enter category",
      "sc.edit.heading": "Edit configuration",
      "search-configuration.search.header.name": "Search Keywords",

      "communication.service.provider": "Service Provider",
      "communication.service.provider.placeholder": "Select Provider",

      "customer-service-tags.heading": "User Tags",
      "customer-service-tags.form.tag.name.label": "TAG NAME",
      "customer-service-tags.form.description.placeholder":
        "Enter description here",
      "customer-service-tags.table.name.placeholder": "Enter table name",
      "customer-service-tags.form.otherdetails.placeholder":
        "Search tags to add",
      "customer-service-tags.noTags": "No tags configured",
      "order.config.generateRandomReferenceNumbers":
        "Generate random reference numbers",
      "order.config.confirmOrderUponSuccessfulPayment":
        "Confirm order only upon successful online payment",
      "order.config.orderTypes": "Order Types",

      "signup.heading": "Create Your Account",
      "signup.buttonText": "Sign Up",
      "signup.name.placeholder": "Enter your name",
      "signup.oldUser.message": "Already have an account? ",
      "signup.oldUser.signinText": "Sign In",
      "user.not.verified.email":
        "Your email id is not verified, you may not receive order notifications.",
      "resend.verification.link": "Resend verification link",
      "email.verified": "Your email has been verified!",
      "password.sent.to.mobile":
        "Your account password has been sent to your mobile.",
      "email.verified.text": "verified",
      "email.not.verified.text": "not verified",
      "use.password.sent.to.registered.mobile":
        "Please use the password sent to your registered mobile number",
      "use.password.sent.to.registered.email":
        "Please use the password sent to your registered email",
      "account.created": "We have created an account for you!",
      "verification.email.sent":
        "A verification email has been sent to your email address.",

      "slots.store.page.title": "Store slot configuration",
      "slots.store.editHeading": "Edit Slot Rule",
      "slots.store.addHeading": "Add Slot Rule",
      "slots.cutoff.mins": "Cutoff in mins",
      "slots.cutoff.placeholder": "Enter cutoff time in mins",
      "slots.store.emptyMessage":
        "There are no slot rules configured for this store",
      "slots.blocking.empty.message":
        "There are no slots configured for blocking",
      "slots.DELIVERY.empty": "No Delivery slots to configure",
      "slots.PICKUP.empty": "No Pickup slots to configure",

      "user.accountBalance.loader.text": "...",
      "user.accountBalance.availableBalance.label": "Available balance",
      "user.accountBalance.input.placeholder": "Enter your amount",
      "user.accountBalance.rechargeButton.text": "Recharge",
      "user.accountBalance.rechargeButton.processing": "Processing...",
      "user.transactions.emptyList.text": "You have no transactions yet",
      "user.transactions.header.billingInfo": "Billing info",
      "user.transactions.header.payment": "Payment",
      "user.payment.loader.text": "Verifying payment, please wait...",
      "user.payment.success.title": "Payment successful",
      "user.payment.fail.title": "Payment failed",
      "user.payment.redirect.link": "Click here to view account balance",

      "leaves.team.startson": "Starts On",
      "leaves.team.endson": "Ends On",
      "leaves.team.appliedon": "Applied On",
      "leaves.team.empty.message": "No leaves",
      "leaves.team.approveable": "Leaves applied by team members",
      "leaves.team.title": "Team leaves",
      "leaves.team.approve.leave": "The leave will be approved",
      "leaves.team.reject.leave": "This leave will be rejected",
      "leaves.team.cancel.title": "Approved leaves that can be cancelled",
      "leaves.team.cancel.leave": "This leave will be cancelled",

      "leaves.dialog.information": "Your leave will be cancelled",
      "leaves.filter.status.approvedcancelleble": "Cancellable",
      "leaves.form.duration": "Duration",
      "leaves.form.reason.placeholder": "Enter reason",
      "leaves.form.type.placeholder": "Choose type",
      "leaves.apply": "Apply",
      "leaves.apply.new": "Apply for leave",
      "leaves.emptyMessage": "There are no leaves available",
      "leaves.filter.status.placeholder": "Choose Status",

      "user.leave-summary.status.Earned": "Earned",
      "user.leave-summary.status.Paternity": "Paternity",
      "user.leave-summary.status.Maternity": "Maternity",
      "user.leave-summary.status.Sick": "Sick",
      "user.account.deletion.confirmation":
        "Are you sure you want to delete your account",
      "user.deletion.confirm": "Confirm deletion",
      "user.verification.code.deletion": "Verification code",
      "user.verification.code.deletion.placeholder": "Enter verification code",
      "user.deletion.verification.code.messgae":
        "A verification code has been sent to your registered",

      "input.requiredMessage": "This cannot be blank",
      "input.invalidFormatMessage": "Please check the format",
      "input.invalidEmailFormat": "Please enter a valid email address",
      "input.invalidEmailPhoneFormat":
        "Please enter a valid email or a phone with country code. e.g: abc@xyz.com or +919845252481",
      "input.invalidUsernameFormat": "Please enter a valid username",
      "input.invalidPhoneFormat": "Please enter a valid phone number",
      "input.fileSizeExeeded": "File size exeeded",
      "input.invalidFileType": "File type is invalid.",
      "input.rangeOverflow": "Please enter a smaller value",
      "input.rangeUnderflow": "Please enter a larger value",
      "input.isUploading":
        "Please wait while the file is being sent to the server",
      "input.invalidNumber": "Enter a positive value",
      "input.invalidStoreName":
        "Please enter a valid store name. It should have at least one English alphabet letter or number. It cannot not start or end with a '-' (hyphen). Successive '-' (hyphens) are also not allowed",
      "login.with.google": "Login with Google",
      "signup.with.google": "Sign up with Google",
      "login.or.text": "OR",
      "dont.have.domain": "I don't have my own domain",
      "have.domain": "I have my own domain",
      "country.text": "Country",
      "enter.country": "Enter Country",
      remember: "Remember",
      "country.selector.text":
        "We will use this to customise your store currency and monthly billing charges",
      "edit.website.text": "View Store",
      "start.building.app": "Start Building your app now !",
      "mobile.app.free": "Get a mobile app for Free now",
      "start.now.free": "Start Now for Free",
      "quick.links.text": "Quick Links",
      "create.order.text": "Create an order",
      "add.product.text": "Add a product",
      "create.campaign.text": "Create a campaign",
      "set.up.store": "Set up your store",
      "importance.online.payment": "Importance of online payment",
      "set.store.few.clicks": "Set up your store in few clicks",
      "tips.tricks.marketing": "Tips & Tricks of social media marketing",
      "revenue.from.stores": "Revenue from stores",
      "top.selling.products": "Top selling products",
      "booked.orders": "Pending orders",
      "delivered.text": "Delivered",
      "get.know.dashboard": "Get to know your dashboard",
      "explore.features.minutes":
        "Explore all the features and set up your online store in minutes.",
      "demo.video": "Demo video",
      "allCourierOrders.shipNow": "Ship now",
      "allCourierOrders.shipNow.loading": "Shipping...",
      "allCourierOrders.shipNow.errorTitle": "Error shipping courier",
      "allCourierOrders.shipNow.errorMsg":
        "Some error occurred while shipping the courier",
      "allCourierOrders.actions.trackOrder": "Track Order",
      "pending.actions": "Pending Actions",
      "steps.to.finish.your.online.store": "Steps to finish your online store",
      "quick.setup.start.money":
        "Quickly set up your store and start making money.",
      "action.my.account.desc": "Manage your account settings",
      "action.online.store.desc": "Make your store look glamorous",
      "action.catalogue.desc": "List the products that you want to sell",
      "action.payments.desc": "Collect and make payments",
      "action.delivery.desc": "End-to-end delivery management",
      "action.marketing.desc": "Promote your online store",
      "store.completed": "Store completed",
      "in.progress": "In Progress",
      "five.reason.online.store": "5 reasons to have your own online store",
      "top.five.features":
        "Top 5 features to look for while choosing your Online Store Builder",
      "how.setup.store": "How to set up your own online store using Zopping?",
      access_block_mobile_apps:
        "To access this functionality, login to www.zopping.com on your web browser.",
      "personalInfo.email.description":
        "Customer, order and store related communications will be mailed here.",
      "personalInfo.phone.description":
        "Customer, order and store related communications will be sent here.",
      "minimum.order.value.description":
        "Your customers cannot place delivery or pickup orders below this value",
      "minimum.order.delivery.value.description":
        "Orders beyond this value will have 0 delivery charge",
      "minimum.order.pickup.value.description":
        "Orders beyond this value will have 0 pickup charge",
      "delivery.fees.description":
        "Flat delivery fees will be charged on all orders whose value is below the 'Minimum order value for free delivery'",
      "pickup.fees.description":
        "Flat pickup fees will be charged on all orders whose value is below the 'Minimum order value for free pickup'",
      "enable.cod.description":
        "Customers can pay for their orders through cash at the time of delivery or pickup",
      "order.types.description":
        "Types of orders that your customers can place on your online store",
      "google.maps.api.key.description":
        "Navigate to Credentials page on your Google Maps Platform account",
      "zopping.account.publish": "Use zopping account to publish your app",
      "download.apk.request.received":
        "Your request to generate the app has been submitted, please be patient while we do our magic. Please visit this page after 30 mins to download your test apk.",
      "download.apk.request.failed":
        "Something went wrong while submitting request. Please try again later.",
      "generate.random.reference.numbers":
        "Order reference numbers will be assigned randomly",
      "order.confirmation.for.online.payments":
        "Applies only to orders where online payment is chosen as a payment mode",
      "menu.item.multi-store": "Stores",
      "menu.item.api-integration": "API Integration",
      "visit-website-text": "Visit Store",

      "dunzo.client.secret": "Client Secret",
      "dunzo.credit": "DUNZO_CREDIT",
      "configure.slot.error.message":
        "No slots found. Please configure slots to place order",
      "input.invalidVehicleNumber": "Please enter a valid vehicle number",
      "input.invalidVendorName": "Please enter a valid vendor name",
      "input.space.not.allowed": "Space not allowed",

      // coachmarks
      "coachmarks.home.1.title": "Visit online store",
      "coachmarks.home.1.description":
        "Click here to preview your online store.",
      "coachmarks.home.2.description":
        "This is your account balance with Zopping. Your monthly billing charges will be deducted from this balance.",
      "coachmarks.home.3.description":
        "Click here to find and update your personal and billing details, and manage leaves.",
      "coachmarks.home.4.description":
        "Click on all the individual tasks to configure the settings for your online store.",
      "coachmarks.home.5.description":
        "Shortcuts to add a product or manually create an order.",
      "coachmarks.home.6.title": "Store Analytics",
      "coachmarks.home.6.description":
        "Top level metrics to look out for on the performance of your store.",
      "got.it": "Got it",
      "coachmarks.home.2.mobile.description":
        "Click here to see your account balance with Zopping. Your monthly billing charges will be deducted from this balance.",
      // #coachmarks
      "select.courier.provider": "Select courier provider",
      "webhook.url": "Webhook URL",
      copy: "Copy",
      copied: "Copied",
      "main.menu": "Main Menu",
      "contact.details.support.email.description":
        "Appears in the footer of your online store",
      "contact.details.whatsapp.support.enquiry.text.description":
        "Pre filled enquriry message for your customer when they begin a conversation with you",
      "contact.details.add.social.media.description":
        "Appears in the footer of your online store with the corresponding social media logo",
      "contact.details.add.app.link.description":
        "Appears in the footer of your online store with the corresponding app store logo",
      "store.add.name.description": "Name of your store",
      "store.add.name.description.limit": "45 Characters Max.",
      "store.add.email.description":
        "Necessary for shipping your orders through 3rd party courier services",
      "communication.allowCall.TWILIO.accountSid":
        "Navigate to the Account Info pane of the Console Dashboard page",
      "communication.allowCall.TWILIO.authToken":
        "Navigate to the Account Info pane of the Console Dashboard page",
      "communication.allowCall.TWILIO.twilioFrom":
        "Twilio phone number that you own, formatted with a '+' and country code, e.g. +16175551212",
      "communication.allowCall.EXOTEL.exotelSid":
        "On your Exotel account, navigate to Settings → API",
      "communication.allowCall.EXOTEL.token":
        "On your Exotel account, navigate to Settings → API",
      "communication.allowSms.TWILIO.accountSid":
        "Navigate to the Account Info pane of the Console Dashboard page",
      "communication.allowSms.TWILIO.authToken":
        "Navigate to the Account Info pane of the Console Dashboard page",
      "communication.allowSms.TWILIO.from":
        "Twilio phone number that you own, formatted with a '+' and country code, e.g. +16175551212",
      "communication.allowSms.MSG91.authKey":
        "On your MSG91 dashboard, select Authkey option from the username dropdown",
      "courier.support.extension.toggle.text":
        "Use third party courier services",
      "courier.support.DUNZO.clientId":
        "Register on Dunzo as a business or send an email to dunzoforbusiness@dunzo.in",
      "courier.support.DUNZO.clientSecret":
        "Register on Dunzo as a business or send an email to dunzoforbusiness@dunzo.in",
      "courier.support.DUNZO.webhook.url":
        "Share this over an email to the Dunzo team",
      "courier.support.SHIPROCKET.email":
        "On your Shiprocket account, navigate to Settings → API → Configure",
      "courier.support.SHIPROCKET.password":
        "On your Shiprocket account, navigate to Settings → API → Configure",
      "courier.show.estimate.and.charges":
        "Show courier estimation and charges to customers",
      "payment.RazorPay.API_KEY":
        "On your Razorpay dashboard, navigate to Settings → API Keys → Generate Key",
      "payment.RazorPay.API_SECRET":
        "On your Razorpay dashboard, navigate to Settings → API Keys → Generate Key",
      "payment.Paytm.MERCHANT_ID":
        "On your PayTM dashboard,MERCHANT_KEY navigate to Developers → API Keys",
      "payment.Paytm.MERCHANT_KEY":
        "On your PayTM dashboard, navigate to Developers → API Keys",
      "payment.Paytm.INDUSTRY_TYPE_ID":
        "On your PayTM dashboard, navigate to Developers → API Keys",
      "payment.Paytm.WEBSITE":
        "On your PayTM dashboard, navigate to Developers → API Keys",
      "payment.Stripe.PUBLISHABLE_KEY":
        "On your Stripe account, navigate to Developers → API Keys",
      "payment.Stripe.SECRET_KEY":
        "On your Stripe account, navigate to Developers → API Keys",
      "order.pack.select.package.label": "SELECT A PACKAGE",
      "order.pack.create.package": "Create Package",
      "order.pack.input.label.name.header": "LABEL NAME",
      "order.pack.input.label.name.placeholder": "Enter a label name",
      "order.pack.input.weight.header": "PACKAGE WEIGHT (in grams)",
      "order.pack.input.weight.placeholder": "Enter weight",
      "order.pack.request.success": "Order pack request sent successfully!",
      "input.value.missing": "This field is required",
      "contact.support": "For Setup Support",
      "use.mobile.app": "For the better experience, please use",
      "use.zopping": "Zopping",
      "download.zooping.app": "Download Zopping App",
      "download.mobile.app": "mobile app",

      "notification.types": "Notification types",
      "push.notification": "Push notification",
      "notification.time": "Notification time",
      "abandoned.cart": "Abandoned Cart",
      "notification.setting.saved.successfully":
        "Notification setting saved successfully",

      "brand.helper.text": "Brand name will appear before the product name",
      "category.helper.text": "Group several products under one category",
      "category.upload.text": "Add Categories",
      "categories.import.successText":
        "  CSV File Uploaded Successfully! Categories will be added and appear after 15 minutes",
      "tag.helper.text": "Search or add custom tags to group your products",
      "upload.image.helper.text":
        "You can upload multiple images of the product",
      "price.helper.text":
        "MRP or price mentioned on the product label (inclusive of all taxes)",
      "discount.helper.text": `Discount amount on the 'Selling Price'`,
      "tax.helper.text":
        "Will be used only for tax invoice purpose. 'Selling Price' mentioned above is inclusive of tax.",
      "stock.helper.text": "Mention the quantity of stock available with you",
      "category.form.helper.text":
        "This appears in the menu bar of your online store",
      "payment.placeholder.PhonePe.MERCHANT_ID": "MerchantID",
      "payment.placeholder.PhonePe.SECRET_KEY keyIndex": "Salt Index",
      "payment.placeholder.PhonePe.SECRET_KEY key": "Salt Key",
      "payment.placeholder.Razor Pay.API_KEY": "Key_ID",
      "payment.placeholder.Razor Pay.API_SECRET": "Key_Secret",
      "payment.placeholder.PayU.MERCHANT_KEY": "Merchant Key",
      "payment.placeholder.PayU.GATEWAY_SALT": "Merchant Salt",
      "payment.PayU.GATEWAY_SALT":
        " On your PayU dashboard, navigate to Collect Payments -> Payment Gateway -> Key Salt Details",
      "payment.PayU.MERCHANT_KEY":
        " On your PayU dashboard, navigate to Collect Payments -> Payment Gateway -> Key Salt Detailss",
      "payment.CCAvenue.ACCESS_CODE":
        " On your CCAvenue M.A.R.S. Account, navigate to Settings -> API Keys",
      "payment.CCAvenue.WORKING_KEY":
        "On your CCAvenue M.A.R.S. Account, navigate to Settings -> API Keys",
      "payment.CCAvenue.MERCHANT_ID":
        " On your CCAvenue M.A.R.S. Account, navigate to Settings -> API Keys",
      "how.setup.customDomain":
        "How to set up a custom domain (URL) for your online store in Zopping?",
      "how.add.products": "How to Add Products to Your Online Store?",
      "how.customize.footer":
        "How to customize the footer of your online store?",

      "verify.text": "Verify",
      "verification.text": "Please verify your domain to proceed further",
      dns_checking: "Checking if the A record exists",
      dns_checking_text: "...",
      dns_verified: "Your A record is available",
      how_to_add_domain: "Learn how to add domain?",
      customDomain: "35.232.68.105",
      customUrl: "stores.zopping.com",
      "customDomain.error.helper.text.title": "Tips to troubleshoot :",
      "customDomain.error.helper.text.point1":
        "1. Ensure that the domain entered above is correct.",
      "customDomain.error.helper.text.point2":
        "2. Check if the CNAME is correctly configured as  ",
      "customDomain.error.helper.text.point3":
        "3. There should not be any A or AAAA records for the configured domain.",
      "customDomain.error.helper.text.point4":
        "4. Check if your CNAME record has been updated by your Domain Registrar across all DNS servers.",
      "customDomain.error.helper.text.point5":
        "5. For additional help, read our support guide or call +91 9916814808.",
      "payment.config.error": "Please configure the payment gateway",
      "product.alert.heading": "Alerts",
      "home.select.date.heading": "Choose date range",
      "home.select.date.subheading": "compare",
      "home.charts.heading.total.revenue": "Total Revenue (in {symbol})",
      "coupon.header.validity.time.start": "Starts At",
      "coupon.header.validity.time.end": "Ends At",
      "themes.customize.primaryButton": "Primary Button",
      "themes.customize.secondaryButton": "Secondary Button",
      "themes.customize.preview": "Preview:",
      "themes.customize.primaryColor.input": "Choose Primary Color:",
      "themes.customize.secondaryColor.input": "Choose Secondary Color:",
      "themes.customize.navigation": "Navigation Sequencing",
      "themes.customize.announcementBar.text": "Have Announcement bar?",
      "themes.customize.announcementBar.backgroundColor": "Background Color:",
      "themes.customize.announcementBar.fontColor": "Font Color:",
      "themes.customize.announcementBar.placeHolder":
        "What you type here will be shown in the announcement bar  of your website",
      "themes.customize.save.success": "Successfully saved!",
      "themes.customize.error.msg": "Some error occurred while saving",
      "product.form.additional.details.heading": "Additional details",
      "wallet.saving.error": "Some error occurred while Saving",
      "shipping.courier.support": "Courier Support",
      "shipping.courier.support.placeholder": "Select Courier Service",
      "sitemap.url.text": "Sitemap URL",
      "sitemap.url.description":
        "Submit this sitemap URL to search engines using Google Search Console or Bing Webmasters.",
      "google.merchant.center.upload.label":
        "Upload the content-api-key.json file here :",
      "google.merchant.center.upload.change.label":
        "To change your Content API Key, Upload the new file here :",
      "google.merchant.center.description":
        "On your Google Merchant Center account, go to Tools and Shipping > Content API > Authentication > New API Key and download the content-api-key.json",
      "google.merchant.center.error.msg": "Upload correct file",
      "google.merchant.center.error.title": "Invalid File",
      "google.merchant.center.success.description":
        "Your Google Merchant Center account is successfully linked to Zopping !",
      "google.merchant.center.success.msg":
        "Your Google Merchant Center account is successfully linked!",
      "google.merchant.center.success.title": "SuccessFully Uploaded",
      "refferal.managemnet.referrer.heading":
        "Referrer (existing customer) Rewards",
      "refferal.managemnet.referee.heading": "Referee (new customer) Rewards",
      "referral.bonus.text": "Referral Bonus",
      "referral.maxBonus.text": "Maximum limit",
      "referral.bonus.description": `Bonus will be credited to the referrer's wallet when the referred customers completes their first order.`,
      "whatmore.input.label": "Store Id",
      "whatmore.input.placeholder": "Enter Store Id",
      "whatmore.success.title": "Success",
      "whatmore.error.title": "Something Went Wrong!",
      "whatmore.save.success.title": "Store Id Successfully Configured",
      "whatmore.save.error.title":
        "Something unexpected occured while saving. Please try again later!",
      "whatmore.description": `Use the store ID from the Whatmore dashboard.`,
      "signup.bonus.text": "Signup Bonus",
      "signup.bonus.placeholder": "Enter the Amount",
      "signup.bonus.description": `Money will be credited upon successful sign up using the referral code.`,
      "referral.save.success.title": "Referral Successfully Configured",
      "referral.save.error.title":
        "Something unexpected occured while saving. Please try again later!",
      "referral.management.success.title": "Success",
      "referral.management.error.title": "Something Went Wrong!",
      "product.property.enable.text": "Enable Product Properties",
      "product.property.add.text": "Add Property",
      "product.property.add.success.text": "Property Name Added Successfully",
      "product.property.add.placeholder": "Enter the Property Name",
      "ticket.filter.order.id": "Order ID",
      "ticket.filter.order.id.placeholder": "Search by Order ID",
      "ticket.filter.ticket.id": "Ticket ID",
      "ticket.filter.ticket.id.placeholder": "Search by Ticket ID",
      "ticket.filter.ticket.status": "Ticket Status",
      "ticket.filter.ticket.status.placeholder": "Select ticket status",
      "order.disputes.heading": "Order Disputes",
      "dispute.table.disputeId": "Dispute Id",
      "dispute.table.orderId": "Order Id",
      "dispute.table.disputeQty": "Dispute Qty",
      "dispute.table.reason": "Reason",
      "dispute.table.resolution": "Requested Resolution",
      "dispute.table.status": "Status",
      "dispute.table.dispure.date": "Dispure Date",
      "dispute.emptyList.message":
        "You have not recieved any order disputes yet. Once your customers place their order disputes on your online store, they will appear here.",
      "communication.allowSms.MSG91.get.template.id.title":
        "Steps to get MSG91 Template IDs:",
      "communication.allowSms.MSG91.get.template.id.point1.a":
        "1. Download the ",
      "communication.allowSms.MSG91.get.template.id.point1.b":
        "that Zopping uses to send to Order and Account related updates to your customers",
      "communication.allowSms.MSG91.get.template.id.point2":
        "2. Login to your DLT portal and create all the templates as mentioned in the downloaded sheet.",
      "communication.allowSms.MSG91.get.template.id.point3":
        "3. Ensure you copy the content exactly in the same manner and format. Do not modify the template content either by adding or removing any character or space",
      "communication.allowSms.MSG91.get.template.id.point4":
        "4. In the place of brand name put your organization name and submit all the templates for approval",
      "communication.allowSms.MSG91.get.template.id.point5":
        "5. Once the templates have been approved, create a template on the MSG91 dashboard.",
      "communication.allowSms.MSG91.get.template.id.point6":
        "6. For creating the SMS templates on MSG91, use the content as mentioned in the MSG91 Content Template column of the attached sheet",
      "communication.allowSms.MSG91.get.template.id.point7":
        "7. Once the template has been created on MSG91 and approve, you will get your MSG91 Template IDs, which have to be entered in the below rows respectively",
      "comminication.allowSms.MSG91.get.template.id.refer.text":
        " Refer this guide.",
      "communication.allowSms.MSG91.get.template.id.point1.sms.content.templates":
        "SMS content templates ",
      "product.collection.banner.button.text": "Banner Button Name",
      "product.collection.banner.button.placeholder": "Enter button name",
      "product.collection.banner.button.link.text": "Button Link",
      "product.collection.banner.button.link.placeholder":
        "Enter button redirection url",
      "product.details.search.label": "Product",
      "product.details.search.placeholder": "Select Product",
      "hotSpot.position.heading.slide": "Spot Position",
      "banner.upload.image.placeholder":
        "Click here to upload or drag your banner",
      "banner.title.placeholder": "Type the banner title",
      "banner.sub.title": "Subtitle",
      "banner.sub.title.placeholder": "Enter the subtitle",
      "banner.description": "Description",
      "banner.description.placeholder": "Enter the description",
      "banner.button.name": "Button Name",
      "banner.button.name.placeholder": "Enter the button name",
      "banner.endTime.date.title": "Valid Till",
      "banner.endTime.time.title": "Valid Till Time",
      "banner.endTime.time.placeholder": "Enter valid till time",
      "banner.endTime.date.placeholder": "Enter valid till date",
      "themes.cc.cardType": "Card type",
      "themes.cc.cardType.circular": "Circular",
      "themes.cc.cardType.rectangle": "Rectangle",
      "themes.customize.layouts.stack": "Layouts",
      "themes.customize.layouts.edit": "Edit",
      "themes.customize.layouts.delete": "Delete",
      "themes.customize.layouts.add": "Add Layout",
      "see.on.map": "See on the map",
      "menu.item.default.nav": "Default Navigation",
      "menu.item.custom.nav": "Custom Navigation",
      "nav.menu.will.be.removed":
        "Switching the navigation type will remove the existing navigations",
      "product.bundled.product": "Bundled Product",
      "product.form.bundle.qty.placeholder": "Enter the quantity",
      "product.form.constituent": "Add Constituent Products",
      "product.form.addonbundle": "Addons Bundle",
      "product.form.addEntities": "Add Entities",
      "themes.layout.visible.text": "Visible On",
      "layout.visible.DESKTOP_WEB": "Desktop",
      "layout.visible.MOBILE_WEB": "Mobile View",
      "layout.visible.ANDROID": "Android",
      "layout.visible.IOS": "Ios",
      "communication.whatsapp.helper.prerequisites": "Prerequisites:",
      "communication.whatsapp.prerequisites.1": "1. A meta developer account.",
      "communication.whatsapp.prerequisites.2": "2. A meta business account.",
      "communication.whatsapp.prerequisites.3":
        "3. A business app with WhatsApp set up.",
      "communication.whatsapp.helper.getToken": "Steps to Obtain a Token:",
      "communication.whatsapp.getToken.1.a": "1. Go to ",
      "communication.whatsapp.getToken.1.link": "business.facebook.com",
      "communication.whatsapp.getToken.1.b": " -> Settings.",
      "communication.whatsapp.getToken.2.a": "2. Navigate to ",
      "communication.whatsapp.getToken.2.b": "Users -> System Users",
      "communication.whatsapp.getToken.2.c":
        " and create a system user with the role of Employee.",
      "communication.whatsapp.getToken.3.a": "3. Navigate to ",
      "communication.whatsapp.getToken.3.b":
        "Accounts -> Apps -> Assign People,",
      "communication.whatsapp.getToken.3.c":
        " select the system user, and grant full control.",
      "communication.whatsapp.getToken.4.a": "4. Navigate to ",
      "communication.whatsapp.getToken.4.b":
        "Accounts -> WhatsApp Accounts -> Assign People,",
      "communication.whatsapp.getToken.4.c":
        " select the system user, and grant full control.",
      "communication.whatsapp.getToken.5.a": "5. Navigate to ",
      "communication.whatsapp.getToken.5.b":
        "Users -> System Users -> Generate Token,",
      "communication.whatsapp.getToken.5.c": ` select the app, set expiry to "Never," choose the whatsapp_business_management and whatsapp_business_messaging permissions, and generate the token.`,
      "communication.whatsapp.helper.senderID": "Steps to Obtain Sender ID:",
      "communication.whatsapp.senderID.1.a": "1. Go to ",
      "communication.whatsapp.senderID.1.link": "developers.facebook.com",
      "communication.whatsapp.senderID.1.b": " -> MyApps",
      "communication.whatsapp.senderID.1.c": " and select the Meta app.",
      "communication.whatsapp.senderID.2.a": "2. Navigate to ",
      "communication.whatsapp.senderID.2.b": "WhatsApp -> API Setup,",
      "communication.whatsapp.senderID.2.c":
        " choose the From number, and find the Phone Number ID. This will be the sender ID for the selected phone number.",
      "product.form.addon.name.placeholder": "Enter Entities",
      "product.form.addon.addButtonText": "Add Entities",
      "product.form.bundle.option.change.message":
        "Are you sure you want to switch the bundle type?",
      "product.form.bundle.deletion.tab.message":
        "Are you sure you want to delete this bundle tab?",
      "product.form.bundle.same.tab.message":
        "Bundle with this name already exists. Please add a bundle with a different name.",
      "product.form.bundle.minimum": "Minimum",
      "product.form.bundle.maximum": "Maximum",
      "product.form.bundle.flexible.bundle.size": "Bundle Size",
      "product.form.bundle.add.bundle.size":
        "Add the bundle size for the flexible bundle!",
      "product.form.bundle.size.exceeded":
        "Groups size cannot be greater than bundle size provided.",
      "product.form.bundle.select.different.product":
        "Item selected already exists, Please select different item for the bundle",
      "product.form.bundle.min.quantity.exceed":
        "Combined minimum quantity can't be greater than bundle size",
      "product.form.bundle.max.quantity.exceed":
        "Maximum quantity can't be greater than bundle size",
      "product.form.bundle.min.quantity.greater.than.max":
        "Minimum size can't be greater than Maximum size",
      "product.form.bundle.add.product": "Add items before saving the bundle",
      "product.form.bundle.show.add.bundle.extension": "Add bundle extension.",
      "orderUpload.uploadFileText": "Click here to upload the file",
      "orderUpload.uploadedCsv.heading": "CSV File Uploaded Successfully!",
      "orderUpload.csvDownloadText": "Download sample CSV",
      "order.create.bulk.text": "Bulk upload",
      "customer.upload-tooltip.note": "NOTE",
      "customer.upload-tooltip.phone":
        "* The phone numbers must be prepended with (+) followed by country code.",
      "customer.upload-tooltip.ex.phone":
        "Eg: Phone number with country code 91 must be +911234567890",
      "customer.upload-tooltip.ins":
        " * For entries with the same client id or information, the corresponding records will be merged.",
      "product.form.bundle.you.can.add": "*Add ",
      "product.form.bundle.minimum.products": " more entity to make a bundle.",
      "banner.layout.checkbox.showOriginal": "Show original",
      "banner.popup.link.placeholder": "Enter redirection url",
      "banner.popup.redirection.text": "Redirection URL",
      "banner.popup.min.duration": "Minimum duration to show banner",
      "banner.popup.min.duration.placeholder":
        "Enter the minimum duration in seconds",
      "banner.popup.auto.dismiss.duration": "Auto dismiss duration",
      "banner.popup.auto.dismiss.duration.placeholder":
        "Enter the auto dismiss duration in seconds",
      "banner.popup.repeat.text": "Repeat",
    },
  },
};

export default data;
